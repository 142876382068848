import { PiCheckCircleDuotone } from "react-icons/pi";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import React, { useEffect } from "react";
// Ensure the GA script is present
const sendConversionEvent = () => {
  window.gtag("event", "conversion", {
    send_to: "AW-976233181/TJ4XCOjyxKcBEN3FwNED", // Replace with your Conversion ID
  });
};

const Thank_you = () => {
  const handleConversion = () => {
    sendConversionEvent();
  };
  useEffect(() => {
    handleConversion(1);
  }, []);

  return (
    <div className="thank-you-w">
      <div className="container">
        <span>
          <PiCheckCircleDuotone />
        </span>
        <h1>Thank You!</h1>
        <p>We received your query successfully!. Our team will contact soon!</p>
        <ul>
          <li>
            {" "}
            <a href="https://www.facebook.com/visualsclipping">
              <FaFacebookF />{" "}
            </a>{" "}
          </li>
          <li>
            {" "}
            <a href="https://x.com/visualsclipping?mx=2">
              <FaTwitter />{" "}
            </a>{" "}
          </li>
          <li>
            {" "}
            <a href="https://www.instagram.com/visuals_clipping/">
              <FaInstagram />{" "}
            </a>{" "}
          </li>
          <li>
            {" "}
            <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHD3u0fF1625wAAAZBdvKrg9yT0fPEAZljO0XrGdl9b2BIoIUHUyqI5trAhI3GRs3ov-YOeWJ2FxHaAwZGJNbWDrJrjTVsvnHSSWPn10McCo-ANnBkMATHlMHarJbcEXL9NRfo=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fvisuals-clipping">
              <FaLinkedinIn />{" "}
            </a>{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Thank_you;
