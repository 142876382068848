import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { PiSealCheckDuotone } from "react-icons/pi";
import { MdOutlineDesignServices } from "react-icons/md";
import { LuTimerReset } from "react-icons/lu";
import { RiScissorsCutLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineWorkHistory } from "react-icons/md";

import { BeforeAfter } from "react-simple-before-after";

import After_Slider_1 from "../assets/img/after-before/jawllery/after-1.webp";
import After_Slider_2 from "../assets/img/after-before/jawllery/after-2.webp";
import After_Slider_3 from "../assets/img/after-before/jawllery/after-3.webp";
import After_Slider_4 from "../assets/img/after-before/jawllery/after-4.webp";
import After_Slider_5 from "../assets/img/after-before/jawllery/after-5.webp";

import After_Slider_6 from "../assets/img/after-before/jawllery/after-6.webp";
import After_Slider_7 from "../assets/img/after-before/jawllery/after-7.webp";
import After_Slider_8 from "../assets/img/after-before/jawllery/after-8.webp";
import After_Slider_9 from "../assets/img/after-before/jawllery/after-9.webp";
import After_Slider_10 from "../assets/img/after-before/jawllery/after-10.webp";

import After_Slider_11 from "../assets/img/after-before/jawllery/after-11.webp";
import After_Slider_12 from "../assets/img/after-before/jawllery/after-12.webp";

import Before_Slider_1 from "../assets/img/after-before/jawllery/before-1.webp";
import Before_Slider_2 from "../assets/img/after-before/jawllery/before-2.webp";
import Before_Slider_3 from "../assets/img/after-before/jawllery/before-3.webp";
import Before_Slider_4 from "../assets/img/after-before/jawllery/before-4.webp";
import Before_Slider_5 from "../assets/img/after-before/jawllery/before-5.webp";

import Before_Slider_6 from "../assets/img/after-before/jawllery/before-6.webp";
import Before_Slider_7 from "../assets/img/after-before/jawllery/before-7.webp";
import Before_Slider_8 from "../assets/img/after-before/jawllery/before-8.jpg";
import Before_Slider_9 from "../assets/img/after-before/jawllery/before-9.webp";
import Before_Slider_10 from "../assets/img/after-before/jawllery/before-10.webp";

import Before_Slider_11 from "../assets/img/after-before/jawllery/before-11.webp";
import Before_Slider_12 from "../assets/img/after-before/jawllery/before-12.webp";

import Service_accodian_png from "../assets/img/jewellery-retouching-services-with-heavenly-precision.png";
import Service_accodian from "../assets/img/jewellery-retouching-services-with-heavenly-precision.webp";

import Logo_png from "../assets/img/logo.png";
import Logo_webp from "../assets/img/logo.webp";
import { lazy, Suspense } from "react";
import Img_Icon from "../assets/img/img-icon.webp";
import Img_Icon_1 from "../assets/img/banner-icon-1.webp";
import Img_Icon_2 from "../assets/img/banner-icon-2.webp";
import Img_Icon_3 from "../assets/img/banner-icon-3.webp";
import Img_Icon_4 from "../assets/img/banner-icon-4.webp";
import Img_Banner_1 from "../assets/img/jewelry-photo-retouching-banner.webp";

import Testi_1 from "../assets/img/testi-icon-1.webp";
import Feature_New_1 from "../assets/img/feature-new-3.webp";

import Satisfied from "../assets/img/100-satisfied.webp";
import Marquee from "react-fast-marquee";

import Company_1 from "../assets/img/px-conversions/company-1.webp";
import Company_2 from "../assets/img/px-conversions/company-2.webp";
import Company_3 from "../assets/img/px-conversions/company-3.webp";
import Company_4 from "../assets/img/px-conversions/company-4.webp";
import Company_5 from "../assets/img/px-conversions/company-5.webp";
import Company_6 from "../assets/img/px-conversions/company-6.webp";
import Company_7 from "../assets/img/px-conversions/company-7.webp";
import { BsShieldFillCheck } from "react-icons/bs";

import Gallery_img_1 from "../assets/img/protfolio/jallery/gallery-1.webp";

import Gallery_img_2 from "../assets/img/protfolio/jallery/gallery-2.webp";

import Gallery_img_3 from "../assets/img/protfolio/jallery/gallery-3.webp";

import Gallery_img_4 from "../assets/img/protfolio/jallery/gallery-4.webp";

import { Helmet } from "react-helmet";

import Gallery_img_5 from "../assets/img/protfolio/jallery/gallery-5.webp";
import Gallery_img_png_5 from "../assets/img/protfolio/jallery/gallery-5.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import pMinDelay from "p-min-delay";

const About_setion2 = lazy(() => pMinDelay(import("./home/About_setion.js"), 5000));

const Jewellery_Photo_Editing = () => {
  var settings_1 = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Helmet>
        <title>Impactful Photo Retouching Services | jewelry image retouching services</title>
        <meta name="description" content="Get High-Quality Jewelry Photo Retouching &amp; Watches Image Editing Services to Boost Your Website's Sales. Zero down payment and round-the-clock assistance." />
        <link rel="canonical" href="https://www.visualsclipping.com/jewelry-retouching-service" />
      </Helmet>

      <section className="c-banner-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-con">
                <h5>
                  <img src={Img_Icon} alt="visuals clipping " />
                  Jewelry Photo Editing
                </h5>
                <h1>
                  Jewelry <span>Retouching </span> Services!
                </h1>
                <ul>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_1} alt="visuals clipping " />
                      </span>
                      100% Satisfaction
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_2} alt="visuals clipping " />
                      </span>
                      No Advanced Payment
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_3} alt="visuals clipping " />
                      </span>
                      Unlimited Revision
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_4} alt="visuals clipping " />
                      </span>
                      10 Image Free Trail
                    </div>
                  </li>
                </ul>

                <div className="c-banner-btn">
                  <Link to="/contact" className="c-btn-1">
                    <span> Get Quote Now</span>
                  </Link>
                  <button data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
                    <span> Get a Free Trial</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-slider">
                <div className="c-banner-slider-list-w">
                  <Slider {...settings_1}>
                    <div className="c-banner-slider-list">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={526}
                        src={Img_Banner_1}
                        width={679}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                    <div className="c-banner-slider-list">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={729}
                        src={Img_Banner_1}
                        width={679}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-about-w c-featurw-pw">
        <div className="container">
          <div className="c-about-hw">
            <h2>
              Benefits to outsource Jewelry Retouching <br></br> Services to <span>Visuals Clipping?</span>{" "}
            </h2>
            <p>Why Outsource Jewelry Retouching to Visuals Clipping?</p>
          </div>
          <div className="cp-fea-list">
            <div className="cp-fea-list-box">
              <div className="cp-fea-list-box-in">
                <span>
                  <LuTimerReset />
                </span>
                Time Saving
              </div>
            </div>
            <div className="cp-fea-list-box">
              <div className="cp-fea-list-box-in">
                <span>
                  <RiScissorsCutLine />
                </span>
                Accuracy
              </div>
              <div className="cp-fea-list-box-in">
                <span>
                  <MdOutlineDesignServices />
                </span>
                100% <br></br>Customized <br></br> Service
              </div>
            </div>

            <div className="cp-fea-list-box c-fea-midel">
              <div className="cp-fea-list-box-in">
                <picture>
                  <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
                  <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo" />
                  <img src={Logo_png} alt="yazzoo homepage banner" />
                </picture>
              </div>
            </div>
            <div className="cp-fea-list-box c-fea-right">
              <div className="cp-fea-list-box-in">
                <span>
                  <MdOutlineWorkHistory />
                </span>
                Professional <br></br> Work
              </div>
              <div className="cp-fea-list-box-in">
                <span>
                  <IoPricetagsOutline />
                </span>
                Cost Effective
              </div>
            </div>
            <div className="cp-fea-list-box c-fea-right">
              <div className="cp-fea-list-box-in">
                <span>
                  <BsShieldFillCheck />
                </span>
                Highest <br></br>standard
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-service-accodian">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="c-service-img">
                <LazyLoadImage
                  alt={"visuals clipping"}
                  height={643}
                  src={Service_accodian}
                  width={857}
                  wrapperProps={{
                    style: { transitionDelay: "0.1s" },
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="c-about-hw c-faq-group-in c-service-faq">
                <h2>
                  Professional <span>Jewelry Retouching</span> Services{" "}
                </h2>
                <p>
                  Jewellery-photo-editing is a sort of fine art. It calls for artistry. It demands a sense of colors n’ shapes to make these precious sparkles a high-octane ambition of any diva. We, Visuals Clipping, make these sparkles a sensation for a lifetime!
                  <a href="https://www.visualsclipping.com/">Visuals Clipping is a leading jewelry retouching company</a> . Your jewelry images look their best using our retouching services, which are advanced and thorough. Our goal is to help you to create professional images that will grab your
                  buyer's attention and help you sell more jewelryietms.
                </p>

                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div class="accordion accordion-flush" id="accordionFlushExample-2">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-3" aria-expanded="false">
                            Get more than just jewelry retouching by adding Jewelry to models
                          </button>
                        </h2>
                        <div id="flush-collapse-3" class="accordion-collapse collapse " data-bs-parent="#accordionFlushExample-2">
                          <div class="accordion-body">
                            <p>
                              Do you feel you can do better to earn customers and increase the sales of your jewelry products? Or are you not getting the result you want with your current published jewelry image on your site or social media? It's time to consider superimpose jewelry to the models in
                              your jewelry photos. Do you know why? This jewellery retouching effect is excellent for oninejewelry sellers because it allows them to showcase their products in a more realistic and relatable way for their target audience. Customers are more likely to purchase since
                              they can visualize themselves wearing the jewelry by seeing how it will appear on a real person. Additionally, it gives the viewer a sense of visualization and can help them in their understanding of the jewelry's dimensions.
                              <br></br>
                              We understand that presenting your jewelry in the best possible light boosts sales and attracts customers. And adding jewelry to models is one of our primary services. We can help you improve the look of your product by adorning a model with a necklace, earrings, or any
                              other piece of jewelry. This is an excellent way to present your product more realistically and give your customers a better idea of how the jewelry will look on them. Our team of experts is proficient and highly skilled in Photoshop and can add jewelry to any model,
                              giving your images a professional and polished appearance. In addition, we offer extra services like high-end jewellery retouching, which will enhance the look of your jewelry and make it even more distinctive. By incorporating shine and sparkle, we can enhance the
                              realism and appeal of your images. So, don't be afraid to place an order for your jewelry photo retouching, where your jewelry will be added to models' images, as this will help you achieve higher sales.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-service-tab-w c-service-quick-w">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Quick and   <span>Effective</span>Jewelry Retouching Service{" "}
            </h2>
            <p>At Visuals Clipping, we understand that the background is one of the most important aspects of any image.</p>
          </div>
          <div className="c-service-tab ">
            <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" data-bs-toggle="pill" data-bs-target="#cp-tab-1" type="button" role="tab" aria-selected="true">
                  Background Removal
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="pill" data-bs-target="#cp-tab-2" type="button" role="tab" aria-selected="false">
                  Diamond Replacement
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button class="nav-link " data-bs-toggle="pill" data-bs-target="#cp-tab-3" type="button" role="tab" aria-selected="true">
                  Metal Recolouring
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="pill" data-bs-target="#cp-tab-4" type="button" role="tab" aria-selected="false">
                  Metal Smooth
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button class="nav-link " data-bs-toggle="pill" data-bs-target="#cp-tab-5" type="button" role="tab" aria-selected="true">
                  {" "}
                  Watch{" "}
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="cp-tab-1" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-4">
                    <BeforeAfter beforeImage={Before_Slider_2} afterImage={After_Slider_2} />
                  </div>
                  <div className="col-md-4">
                    <BeforeAfter beforeImage={Before_Slider_3} afterImage={After_Slider_3} />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="cp-tab-2" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-4">
                    <BeforeAfter
                      beforeImage={Before_Slider_4}
                      afterImage={After_Slider_4}
                      /* Other Props */
                    />
                  </div>
                  <div className="col-md-4">
                    <BeforeAfter beforeImage={Before_Slider_1} afterImage={After_Slider_1} />
                  </div>
                </div>
              </div>

              <div class="tab-pane fade " id="cp-tab-3" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-4">
                    <BeforeAfter
                      beforeImage={Before_Slider_5}
                      afterImage={After_Slider_5}
                      /* Other Props */
                    />
                  </div>
                  <div className="col-md-4">
                    <BeforeAfter beforeImage={Before_Slider_6} afterImage={After_Slider_6} />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade " id="cp-tab-4" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-4">
                    <BeforeAfter beforeImage={Before_Slider_7} afterImage={After_Slider_7} />
                  </div>
                  <div className="col-md-4">
                    <BeforeAfter beforeImage={Before_Slider_11} afterImage={After_Slider_11} />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade " id="cp-tab-5" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-4">
                    <BeforeAfter
                      beforeImage={Before_Slider_12}
                      afterImage={After_Slider_12}
                      /* Other Props */
                    />
                  </div>
                  <div className="col-md-4">
                    <BeforeAfter beforeImage={Before_Slider_9} afterImage={After_Slider_9} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cn-feature-main cp-service-feature">
        <div className="container">
          <div className="c-feature-w">
            <div className="c-heading-w">
              <h2>
                What <span>We Offer</span>{" "}
              </h2>
              <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! Here are the top reasons why we should be your go-to choose for all your photo editing and retouching needs: </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Jewelry background Removal</h3>
                  <p>
                    It’s a photo editing technique that allows to remove the background from an image of jewelry. This is useful when you want to isolate the subject of an image or when you want to create a transparent background. Background removal becomes important in order to highlight the main
                    subject in the picture also.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Metal Smoothening</h3>
                  <p>
                    Jewelry metal smoothing is an important process in the presentation of jewelry items on any buyers’ portal. It involves removing any rough edges or high ridges from the metal to create a smooth and polished surface. Jewellery editing is a very sensitive art to master because of
                    the fineness n’ class it demands.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Metal Recolouring</h3>
                  <p>
                    Jewelry metal recoloring revitalizes the appearance of your jewelry. With this technique, our skilled retouchers can change the color of the jewellery metal, giving it a fresh new look. Whether you want to go from gold to silver or to copper or vice versa, with metal recoloring,
                    our expert retouchers lets it enrich the beauty of your jewellery items
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Jewelry Enhancement</h3>
                  <p>Jewelry enhancement is the process of improving its appearance. Techniques such as electroplating, rhodium plating, and enameling can be used to enhance the color, shine, and polish of jewelry. These enhancements can make your jewelry look more beautiful n’ attractive.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />
                  <h3>High-end Jewelry Retouching</h3>
                  <p>
                    It’s a jewelry photo retouching services that enhance the quality of jewellery images to attract online shoppers. Of course, it takes different tools, techniques, color sense, and mindset to accomplish a perfect image. It involves removing the background, dust, scratches, and
                    blemishes, making shadows and reflections, and color correction
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Watch Photo Editing</h3>
                  <p>
                    It’s a process that enhances the appearance of your watch photos. With the help of skilled retouchers, you can have your watch photos edited to remove any flaws, enhance its color& shine, and even add or remove unwanted elements. Trust our experts to make your watch photos look
                    their best.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-testimonial-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="c-testi-in">
                <div className="c-heading-w">
                  <h5>
                    <img src={Testi_1} alt="visuals clipping " /> Testimonial
                  </h5>
                  <h2>
                    What Our <span>Client</span> Says?
                  </h2>
                  <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                  <a href="#" className="c-btn-1">
                    <span> Contact</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="c-testi-list">
                <div className="slider-container">
                  <Suspense fallback={<p> Loding </p>}>
                    <About_setion2 />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-price-table-w">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Jewellery Retouching <span> Price Chart </span>{" "}
            </h2>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tr>
                <th>Work</th>
                <th>Basic ($2/image)</th>
                <th>Medium ($3.5/image)</th>
                <th>High- end ($5/image)</th>
              </tr>
              <tr>
                <td>
                  {" "}
                  <strong>Background Removal</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Dust / Reflection removal</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Drop Shadow / Reflection</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Image Resizing</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Maximum Image Size</strong>{" "}
                </td>
                <td>1000X1000</td>
                <td>2000X2000</td>
                <td>No Size Limit</td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Image Enhancement</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Color Correction</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Metal Smoothening</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Metal Recolouring</strong>{" "}
                </td>
                <td></td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Diamond Replacement</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Gems / Stone Cleaning</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Multiple Clipping Path</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Metal Shine Enhancement</strong>{" "}
                </td>
                <td></td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Ring Shank Redraw</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Creative Retouching</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Unwanted Elements Removal</strong>{" "}
                </td>
                <td></td>
                <td></td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Multiple Format (JPG, PNG, Tiff, PSD, Other)</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Focus Stacking</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>

      <section className="c-portfolio-service">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Our Photo Editing  <span>Portfolio </span>{" "}
            </h2>
            <p>At Visuals Clipping, we understand that the background is one of the most important aspects of any image.</p>
          </div>
          <div className="c-porfolio-in">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="c-porfolio-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={670}
                    src={Gallery_img_1}
                    width={857}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_2}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_3}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_4}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_5}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <Link to="/contact" className="c-btn-1">
                <span>Get a Free Trial</span>{" "}
              </Link>
            </div>
          </div>
          <div className="c-porfolio-con">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="c-porfolio-con">
                  <div className="c-heading-w text-left">
                    <h2>
                      Premium Jewelry <span>Retouching</span>
                    </h2>
                    <p>
                      Are you fed up with losing clients due to poor jewelry photos? You want to show your best work but are disappointed by poor-quality photos! Don't be disappointed and embrace. Our expert jewelry editors can solve your problem by enhancing the images that reflect the clean lines
                      and brings out the beauty of the ornament at the forefront. Our knowledgeable team of jewelry editors knows how to bring out the best in each jewelry piece by grasping light and shadow's subtleties. You can get complete jewelry photo editing under one roof. You can try our
                      services for free using the Free trial facility.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="c-porfolio-con">
                  <div className="c-heading-w text-left">
                    <h2>
                      Jewellery retouching for <span>professional</span> result
                    </h2>
                    <p>
                      It can often be challenging to take photos of jewelry that look good and show off the piece in the best light possible. One of the main problems with taking photos of jewelry is that it is often challenging to hide reflection signs of scratches, tears, and cracks on the surface
                      of the jewelry. It can be a big problem if you are trying to sell a piece of jewelry, as it can make the jewelry look less valuable. We can repair all kinds of damage to your jewelry, from minor scratches to major cracks. We do jewellery editing in photoshop to give you the
                      professional result. We are giving our services in many countries like USA, the UK, France, Germany, Italy, Canada, Switzerland, Belgium and many more. Our solutions ensure your photos look their best, and we'll help you say goodbye to the damage holding you back.
                    </p>
                    <p>Take a glance at our other solutions</p>
                    <ul>
                      <li>Get professionally enhanced jewelry images.</li>
                      <li>Assured delivery of flawless results</li>
                      <li>Get rid of jewelry imperfection</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-about-w c-inner-company">
        <div className="container">
          <div className="c-about-hw">
            <h2>
              Our Trusted <span>Customers</span>{" "}
            </h2>

            <LazyLoadImage
              alt={"visuals clipping"}
              height={82}
              src={Satisfied}
              width={224}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
          </div>
          <Marquee>
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_1}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_2}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_3}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_4}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_5}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_6}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_7}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
          </Marquee>
        </div>
      </section>
    </>
  );
};

export default Jewellery_Photo_Editing;
