import React from "react";
import { Link } from "react-router-dom";
import { RiMessage2Fill } from "react-icons/ri";
import { FileUploader } from "react-drag-drop-files";
import { BiMailSend } from "react-icons/bi";
import contact_bg from "../assets/img/contact-bg.webp";
import contact_bg_png from "../assets/img/contact-bg.png";
import { FaWhatsapp } from "react-icons/fa6";
import { MdOutlinePermPhoneMsg } from "react-icons/md";
import { PiPhoneCall } from "react-icons/pi";
import Contact_form from "./Contact_form";
import { Helmet } from "react-helmet";

import { LazyLoadImage } from "react-lazy-load-image-component";
const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Our Team For Better experience | Visuals Clipping</title>
        <meta name="description" content="If you have any query related to our services, you can Contact Our Team For a Better experience at the Visuals Clipping website. Our executive will get in touch with you." />
        <link rel="canonical" href="https://www.visualsclipping.com/contact" />
      </Helmet>
      <section className="c-contact-pw">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="cp-contact-left">
                <div className="cp-contact-hw">
                  <h5>
                    <span>
                      <RiMessage2Fill />
                    </span>
                    Contact us
                  </h5>
                  <h1 className="mb-3">Reach Out Us</h1>
                  <p>Have a project in mind? Let's talk.</p>
                </div>
                <Contact_form />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="cp-contact-info">
                <div className="text-center">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={465}
                    src={contact_bg}
                    width={679}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />
                </div>
                <h2>Contact Our Team</h2>
                <p>
                  Questions, comments, or suggestions?<br></br> Simply fill in the form and we’ll be in touch shortly
                </p>
                <a href="mailto:info@visualsclipping.com">
                  <div class="d-flex align-items-center mb-4">
                    <div class="info-icon">
                      <BiMailSend />
                    </div>
                    <div class="info-item">
                      <p>Email</p>
                      <p>info@visualsclipping.com</p>
                    </div>
                  </div>
                </a>
                <a href="tel:+91 9654548666">
                  <div class="d-flex align-items-center mb-4">
                    <div class="info-icon">
                      <PiPhoneCall />
                    </div>
                    <div class="info-item">
                      <p>Phone</p>
                      <p>+91 9654548666</p>
                    </div>
                  </div>
                </a>
                <a href="tel:+ 1(888)535-7543">
                  <div class="d-flex align-items-center mb-4">
                    <div class="info-icon">
                      <MdOutlinePermPhoneMsg />
                    </div>
                    <div class="info-item">
                      <p>USA/Canada Toll Free</p>
                      <p> + 1(888)535-7543</p>
                    </div>
                  </div>
                </a>
                <a href="https://api.whatsapp.com/send?phone=9654548666">
                  <div class="d-flex align-items-center mb-4">
                    <div class="info-icon">
                      <FaWhatsapp />
                    </div>
                    <div class="info-item">
                      <p>Whatsapp</p>
                      <p> +91 9654548666</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
