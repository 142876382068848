import React from 'react';
import { lazy, Suspense } from "react";


import { IoPricetagsOutline } from "react-icons/io5";
import { RiMailSendLine } from "react-icons/ri";
import Logo_png from '../assets/img/logo.png' 
import Logo_webp from '../assets/img/logo.webp' 
import { TbPhotoUp } from "react-icons/tb";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Img_Icon from '../assets/img/img-icon.webp';
import Testi_1 from "../assets/img/testi-icon-1.webp";
import Img_Icon_1 from '../assets/img/banner-icon-1.webp';
import Img_Icon_2 from '../assets/img/banner-icon-2.webp';
import Img_Icon_3 from '../assets/img/banner-icon-3.webp';
import Img_Icon_4 from '../assets/img/banner-icon-4.webp';
import Image_banner from '../assets/img/Jewelry/jewelry-photo-editing.webp';
import Feature_New_1 from '../assets/img/landing/landing-icon-2.webp';
import pMinDelay from 'p-min-delay';

import Landing_img_1 from '../assets/img/Jewelry/jewelry-background-removal-service.webp';
import Landing_img_2 from '../assets/img/Jewelry/jewelry-diamond-replacement-service.webp';
import Landing_img_3 from '../assets/img/Jewelry/jewelry-metal-re-colouring-service.webp';
import Landing_img_4 from '../assets/img/Jewelry/jewelry-metal-smoothning-retouching.webp';
import Landing_img_5 from '../assets/img/Jewelry/watch-photo-retouching-services.webp';
import Landing_img_6 from '../assets/img/Jewelry/high-end-jewelry-retouching.webp';

import Contact_form from './Contact_form.js';

import { RxLapTimer } from "react-icons/rx";





const About_setion = lazy(() => pMinDelay(import('./home/About_setion.js'),5000));

const Feature_section = lazy(() => import("./home/Feature_section.js"));



const Jewelry_photo_editing = () => {
    
  return (
    
    <>
    <Helmet>
        <title>Professional Jewelry Photo Editing Services | Visuals Clipping</title>
        <meta name="description" content="Enhance your jewelry images with Visuals Clipping’s expert retouching services. Offering diamond replacement, background removal, metal re-coloring, and more. Get start with our free 10-image trial"/>
     </Helmet>

  <section className="c-banner-w c-landing-banner">
	    <div className="container">
		<div className="row align-items-center">
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-con">
					<h5>

          <LazyLoadImage 
						alt= {"visuals clipping"}
						height={24}
						src={Img_Icon_1} 
						width={24}  
						
						wrapperProps={{
							
						
						}}
						/>
                    

                    Jewelry Photo Editing 

					</h5>
					<h1>
           <span>Jewelry Photo  Editing </span> Services!

                    </h1>
             
                    <ul>	
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_1} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								
								100% Satisfaction
							</div>
						</li>	
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_2} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								No Advanced Payment
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_3} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								
								Unlimited Revision
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_4} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								
								10 Image Free Trail
							</div>
						</li>
					</ul>
                    
					<div className="c-banner-btn">
						<Link  to="/contact" className="c-btn-1">
							<span>	Get Quote Now</span>
						</Link>
						<button  data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
							<span>	Get a Free Trial</span>
						</button>
					</div>
				</div>
			</div>
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-slider">
					<div className="c-banner-slider-list-w">
                         <div className="c-banner-slider-list">


                           <LazyLoadImage 
                            alt={"Jewelry Photo Editing Service"}
                                            height={592}
                                            src={Image_banner} 
                                            width={678}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            /> 

                          
                            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section className='c-about-w c-featurw-pw landing-about-top'>
    <div className='container'>
        <div className='row'>
            <div className="col-lg-5 col-md-12">
              <div className="c-testi-in">
                  <div className='c-landing-form'>
                      <h3> Get Quote Now</h3>   
                      <Contact_form />                   
                  </div>                          
              </div>
            </div>  
            <div className="col-lg-7 col-md-12">
            <div className='c-about-hw'>
             <h2>Why Choose Visuals Clipping for  <span>Jewelry Retouching Services?</span> </h2>
             <p>With over 15 years of experience, we understand the nuances of jewelry editing, offering meticulous and custom-tailored retouching to enhance every detail. Start with a 10-image free trial and see the difference in quality firsthand.</p>
           
        </div>
        <div className='cp-fea-list'>


              <div className='cp-fea-list-box'>
              <div className='cp-fea-list-box-in'>
                  <span>
                  <RiMailSendLine />
                  </span>
                  12 Hours<br></br> Delivery

              </div>
              <div className='cp-fea-list-box-in'>
                  <span>
                  <RxLapTimer />
                  </span>
                  Time  <br></br>Saving 
              </div>
          </div>

          <div className='cp-fea-list-box c-fea-midel'>
              <div className='cp-fea-list-box-in'>
                  <picture>
                      <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
                      <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo"/>
                      <img src={Logo_png} alt="yazzoo homepage banner"  />
                  </picture>
              </div>
          </div>
          <div className='cp-fea-list-box c-fea-right'>
              <div className='cp-fea-list-box-in'>
                  <span>
                  <IoPricetagsOutline />
                  </span>
                  Cost 
                  <br></br> Effective
              </div>
              <div className='cp-fea-list-box-in'>
                  <span>
                    <TbPhotoUp />
                  </span>
                  Professional <br></br> Work
              </div>
          </div>


        </div>                              
            </div>                                   
        </div>                                        
        
    </div>
</section>  
    

      <section className='cn-feature-main cp-service-feature'>
    <div className='container'>
    <div className='c-feature-w'>
          <div className='c-heading-w'>
                <h2>Bring Out the Brilliance with Expert  <span>Jewelry Photo Editing.</span></h2>
                <p>Our jewelry retouching services are designed to make every piece look its finest. From enhancing diamonds to perfecting metal surfaces, we use precision techniques to achieve striking results that capture customers’ attention. </p>
          </div>
            <div className='row'>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>High-End Jewelry Retouching
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"High-end Jewelry Photo Editing"}
                                            height={287}
                                            src={Landing_img_6} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Our high-end jewelry retouching service is crafted to highlight the unique brilliance of every gem and metal. We enhance diamond sparkle, adjust lighting, and polish metal surfaces to make your jewelry appear luxurious and refined. Whether it’s rings, necklaces, or bracelets, we apply advanced retouching techniques that draw out the allure of each piece, making them stand out in high-resolution images for online stores or catalogs.</p>
                       
                    </div>
                </div>

                 <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"Drop-Shadow Creation"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Jewelry Background Removal
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Jewelry Photo Editing Service"}
                                            height={287}
                                            src={Landing_img_1} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Background removal is essential for creating clean, professional product images. We meticulously cut out jewelry pieces from their backgrounds to eliminate distractions and allow the details to shine. This service is ideal for eCommerce platforms where a seamless, isolated look is necessary to captivate customers. Our team ensures the edges remain sharp and natural, providing a flawless presentation every time.</p>
                       
                    </div>
                </div>   
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Metal Recoloring
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Jewelry Metal Smoothning"}
                                            height={287}
                                            src={Landing_img_3} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Metal recoloring allows you to showcase jewelry in various finishes without additional photoshoots. We adjust hues to represent gold, silver, rose gold, and more with accuracy, ensuring color consistency across all images. This service enables you to display multiple versions of a piece, increasing its appeal to customers with different style preferences.
                            </p>
                       
                    </div>
                </div>   
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Metal Smoothing
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"High-end Watch Photo Retouching"}
                                            height={287}
                                            src={Landing_img_4} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Our metal smoothing service removes imperfections, scratches, and spots on the metal surface, giving jewelry a polished and sleek appearance. We refine the surface texture and enhance its reflective qualities, making each piece appear brand new. Ideal for luxury items, this service guarantees an impeccable look that aligns with the high-end quality of your brand.
                            </p>
                       
                    </div>
                </div>   

                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Diamond Replacement
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Jewelry Diamond Replacement"}
                                            height={287}
                                            src={Landing_img_2} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Sometimes, diamonds and other gemstones don’t appear as brilliant as they should in photographs. Our diamond replacement service allows you to enhance or replace diamonds digitally, giving each stone the clarity and sparkle that captures attention. We ensure accurate color, light, and contrast adjustments, providing lifelike enhancements to make every gemstone dazzle.
                            </p>
                       
                    </div>
                </div> 

                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Watch Photo Editing
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Product Image Resizing Service For Web"}
                                            height={287}
                                            src={Landing_img_5} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p> 
                            Watches require special attention to detail, particularly in capturing dials, hands, and intricate elements. Our watch photo editing service enhances clarity, balances lighting, and refines metal and glass reflections. We create a pristine, realistic look that appeals to luxury watch buyers and ensures the product’s sophistication is clear in every shot.
                            </p>
                       
                    </div>
                </div>
                                     
               
               
            </div>
        </div>
    </div>
</section>   
<section className="c-testimonial-w c-landing-teti-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="c-testi-in">
                  <div className='c-landing-form'>
                      <h3> Get Quote Now</h3>   
                      <Contact_form />                   
                  </div>                          
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="c-testi-list mt-0">
              <div className="c-testi-in mb-5">
                <div className="c-heading-w">
                  <h5>
                    <img src={Testi_1} alt="visuals clipping " /> Testimonial
                  </h5>
                  <h2>
                    What Our <span>Client</span> Says?
                  </h2>
                  <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                  
                </div>
              </div>
                <div className="slider-container">
                  <Suspense fallback={<p> Loding </p>} >
                    <About_setion />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
     <Suspense fallback={<p className="c-feature-lodder"> Loding... </p>}>
        <Feature_section />
      </Suspense>
      <section className="c-faq-w landing-faq">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Frequently <span>Asked</span> Questions
            </h2>
          </div>
          <div className="row">
            
            <div className="col-lg-7 col-md-12 mt-auto mb-auto">
              <div className="c-faq-list">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      What file formats do you support for delivery?
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We support JPEG, PNG, PSD, and TIFF formats based on your preferences.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      What is included in high-end jewelry retouching?
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Our high-end jewelry retouching covers gemstone enhancement, metal smoothing, color correction, and precise detailing for a premium look.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Can you adjust the color of metals in my jewelry photos?
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>
                        Yes, we offer metal recoloring services to display jewelry in different metal finishes, like gold, silver, and rose gold, without reshooting.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                      How quickly can you deliver edited jewelry photos?
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We offer a standard 24-hour delivery and 3-6 hour rush delivery options, depending on project requirements.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading5">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                      Is there a free trial available for jewelry retouching?
                      </button>
                    </h2>
                    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Yes, we provide a 10-image free trial so you can evaluate our quality and service firsthand before making a commitment.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading6">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapseThree">
                      Can you remove backgrounds from intricate jewelry pieces?
                      </button>
                    </h2>
                    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Absolutely. We specialize in detailed background removal, ensuring even the most delicate jewelry pieces are isolated perfectly.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading7">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                      What if I need revisions on my retouched images?
                      </button>
                    </h2>
                    <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We offer unlimited revisions until you’re completely satisfied with the final images.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading7">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapseThree">
                      How do you handle the confidentiality of my jewelry images?
                      </button>
                    </h2>
                    <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Your images are securely managed, and we follow strict confidentiality protocols to ensure complete privacy.</p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="c-faq-img">
                  <div className='c-landing-form'>
                      <h3> Get Quote Now</h3>   
                      <Contact_form />                   
                  </div>                          
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  )
}

export default Jewelry_photo_editing
