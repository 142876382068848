import React from 'react';
import { lazy, Suspense } from "react";


import { IoPricetagsOutline } from "react-icons/io5";
import { RiMailSendLine } from "react-icons/ri";
import Logo_png from '../assets/img/logo.png' 
import Logo_webp from '../assets/img/logo.webp' 
import { TbPhotoUp } from "react-icons/tb";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Img_Icon from '../assets/img/img-icon.webp';
import Testi_1 from "../assets/img/testi-icon-1.webp";
import Img_Icon_1 from '../assets/img/banner-icon-1.webp';
import Img_Icon_2 from '../assets/img/banner-icon-2.webp';
import Img_Icon_3 from '../assets/img/banner-icon-3.webp';
import Img_Icon_4 from '../assets/img/banner-icon-4.webp';
import Image_banner from '../assets/img/background-removal/background-removal-service.webp';
import Feature_New_1 from '../assets/img/landing/landing-icon-2.webp';
import pMinDelay from 'p-min-delay';
import Landing_img_1 from '../assets/img/background-removal/complex-product-background-removal.webp';
import Landing_img_2 from '../assets/img/background-removal/product-photo-editing-service.webp'
import Landing_img_3 from '../assets/img/background-removal/simple-clipping-path-service.webp'
import Contact_form from './Contact_form.js';

import { RxLapTimer } from "react-icons/rx";
import Landing_img_4 from '../assets/img/background-removal/hair-masking-service.webp';
import Landing_img_5 from '../assets/img/background-removal/image-resizing-padding-a.webp';




const About_setion = lazy(() => pMinDelay(import('./home/About_setion.js'),5000));

const Feature_section = lazy(() => import("./home/Feature_section.js"));



const Background_Removal_Services = () => {
    
  return (
    
    <>
    <Helmet>
        <title>Background Removal Service for Professional Images | Visuals Clipping</title>
        <meta name="description" content="Get clean, high-quality product images with Visuals Clipping’s professional background removal services. Perfect for eCommerce, our precise, hand-drawn clipping paths ensure a seamless look. Enjoy fast turnaround, a free trial, and unlimited revisions."/>
     </Helmet>

  <section className="c-banner-w c-landing-banner">
	    <div className="container">
		<div className="row align-items-center">
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-con">
					<h5>

          <LazyLoadImage 
						alt={"visuals clipping"}
						height={24}
						src={Img_Icon_1} 
						width={24}  
						
						wrapperProps={{
							
						
						}}
						/>
                    

                    Background  Removal
					</h5>
					<h1>
          Professional <span> Background 
Removal </span> Services!

                    </h1>
             
                    <ul>	
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_1} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								
								100% Satisfaction
							</div>
						</li>	
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_2} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								No Advanced Payment
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_3} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								
								Unlimited Revision
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_4} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								
								10 Image Free Trail
							</div>
						</li>
					</ul>
                    
					<div className="c-banner-btn">
						<Link  to="/contact" className="c-btn-1">
							<span>	Get Quote Now</span>
						</Link>
						<button  data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
							<span>	Get a Free Trial</span>
						</button>
					</div>
				</div>
			</div>
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-slider">
					<div className="c-banner-slider-list-w">
                         <div className="c-banner-slider-list">


                           <LazyLoadImage 
                            alt={"Background Removal Services"}
                                            height={592}
                                            src={Image_banner} 
                                            width={678}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            /> 

                          
                            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section className='c-about-w c-featurw-pw landing-about-top'>
    <div className='container'>
        <div className='row'>
            <div className="col-lg-5 col-md-12">
              <div className="c-testi-in">
                  <div className='c-landing-form'>
                      <h3> Get Quote Now</h3>   
                      <Contact_form />                   
                  </div>                          
              </div>
            </div>  
            <div className="col-lg-7 col-md-12">
            <div className='c-about-hw'>
             <h2>Why Choose Visuals Clipping for <span>Background Removal Services?</span> </h2>
             <p>We deliver fast, accurate, and customized background removal to meet your exact requirements. With a commitment to quality and 24-hour delivery options, we enhance your product images to boost engagement and conversions.</p>
           
        </div>
        <div className='cp-fea-list'>


              <div className='cp-fea-list-box'>
              <div className='cp-fea-list-box-in'>
                  <span>
                  <RiMailSendLine />
                  </span>
                  12 Hours<br></br> Delivery

              </div>
              <div className='cp-fea-list-box-in'>
                  <span>
                  <RxLapTimer />
                  </span>
                  Time  <br></br>Saving 
              </div>
          </div>

          <div className='cp-fea-list-box c-fea-midel'>
              <div className='cp-fea-list-box-in'>
                  <picture>
                      <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
                      <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo"/>
                      <img src={Logo_png} alt="yazzoo homepage banner"  />
                  </picture>
              </div>
          </div>
          <div className='cp-fea-list-box c-fea-right'>
              <div className='cp-fea-list-box-in'>
                  <span>
                  <IoPricetagsOutline />
                  </span>
                  Cost 
                  <br></br> Effective
              </div>
              <div className='cp-fea-list-box-in'>
                  <span>
                    <TbPhotoUp />
                  </span>
                  Professional <br></br> Work
              </div>
          </div>


        </div>                              
            </div>                                   
        </div>                                        
        
    </div>
</section>  
    

      <section className='cn-feature-main cp-service-feature'>
    <div className='container'>
    <div className='c-feature-w'>
          <div className='c-heading-w'>
                <h2>Enhance Product Presentation with <span>Background Removal.</span></h2>
                <p>Visuals Clipping specializes in precise background removal, bringing focus to your product’s key details and eliminating distractions. Our expert team ensures clean, seamless backgrounds for an impactful visual appeal that’s perfect for eCommerce. </p>
          </div>
            <div className='row'>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Product Background Removal
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Complex Product Background Removal"}
                                            height={287}
                                            src={Landing_img_2} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>For professional-looking product images, our background removal service isolates items from their original settings, creating a clean and uniform look. This approach is ideal for eCommerce listings where consistent backgrounds are essential for a cohesive brand image. Whether you need white backgrounds, transparent backgrounds, or something custom, our experienced team ensures flawless results.</p>
                       
                    </div>
                </div>

                 <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"Drop-Shadow Creation"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Drop-Shadow Creation
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Product Drop Shadow"}
                                            height={287}
                                            src={Landing_img_3} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Adding a drop shadow to product images provides a realistic touch that enhances depth and dimension. We create natural-looking shadows tailored to the lighting and angle of each product. This service is perfect for eCommerce platforms and catalog images, making products appear grounded and visually appealing.</p>
                       
                    </div>
                </div>   
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Complex Background Removal
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Complex Background Removal"}
                                            height={287}
                                            src={Landing_img_1} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>For intricate products with challenging backgrounds, our complex background removal service provides precise editing to handle fine details. Using advanced techniques, we ensure even the most intricate items—such as jewelry and accessories—are flawlessly separated from their backgrounds without losing detail or quality.
                            </p>
                       
                    </div>
                </div>   
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Image Masking
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Image Masking Services"}
                                            height={287}
                                            src={Landing_img_4} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Our image masking service is ideal for removing backgrounds from objects with fine edges or transparent areas. Whether it’s hair, fabric, or glass, we use layer and channel masking techniques in Photoshop to capture every detail accurately, delivering sharp, professional-quality images.
                            </p>
                       
                    </div>
                </div>   

                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Image Resizing
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Product Image Resizing Service For Web"}
                                            height={287}
                                            src={Landing_img_5} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>We offer image resizing as part of our background removal services to ensure your images meet the exact specifications of eCommerce platforms, social media, or print requirements. This service helps streamline your workflow by providing ready-to-use images in the perfect resolution and dimensions
                            </p>
                       
                    </div>
                </div> 
                                     
               
               
            </div>
        </div>
    </div>
</section>   
<section className="c-testimonial-w c-landing-teti-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="c-testi-in">
                  <div className='c-landing-form'>
                      <h3> Get Quote Now</h3>   
                      <Contact_form />                   
                  </div>                          
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="c-testi-list mt-0">
              <div className="c-testi-in mb-5">
                <div className="c-heading-w">
                  <h5>
                    <img src={Testi_1} alt="visuals clipping " /> Testimonial
                  </h5>
                  <h2>
                    What Our <span>Client</span> Says?
                  </h2>
                  <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                  
                </div>
              </div>
                <div className="slider-container">
                  <Suspense fallback={<p> Loding </p>} >
                    <About_setion />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
     <Suspense fallback={<p className="c-feature-lodder"> Loding... </p>}>
        <Feature_section />
      </Suspense>
      <section className="c-faq-w landing-faq">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Frequently <span>Asked</span> Questions
            </h2>
          </div>
          <div className="row">
            
            <div className="col-lg-7 col-md-12 mt-auto mb-auto">
              <div className="c-faq-list">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      What file formats do you support for delivery?
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We support JPEG, PNG, PSD, and TIFF formats based on your preferences.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      What types of backgrounds can you remove from images?
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We can remove simple, complex, or patterned backgrounds, creating clean, transparent, or custom backgrounds as needed.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Can you add shadows or reflections to my images?
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>
                        Yes, we offer drop shadow and reflection creation services for a more realistic, professional look.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                      How quickly can you deliver the edited images?
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Our standard turnaround is 24 hours, with rush delivery options within 3-6 hours, depending on project size and complexity.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading5">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                      Do you offer a free trial for background removal?
                      </button>
                    </h2>
                    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Yes, we provide a 10-image free trial so you can see our quality before committing to a full order.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading6">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapseThree">
                      Will my images be edited by hand?
                      </button>
                    </h2>
                    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Yes, we use hand-drawn clipping paths and advanced masking techniques to ensure high precision and detail.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading7">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                      What if I need revisions on my background removal images?
                      </button>
                    </h2>
                    <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We offer unlimited revisions until you’re satisfied with the final product.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading7">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapseThree">
                      Can you handle bulk orders for large eCommerce stores?
                      </button>
                    </h2>
                    <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Absolutely. Our team is equipped to manage bulk projects efficiently without compromising quality.</p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="c-faq-img">
                  <div className='c-landing-form'>
                      <h3> Get Quote Now</h3>   
                      <Contact_form />                   
                  </div>                          
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  )
}

export default Background_Removal_Services
