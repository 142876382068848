import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { PiSealCheckDuotone } from "react-icons/pi";
import { MdOutlineDesignServices } from "react-icons/md";
import { LuTimerReset } from "react-icons/lu";
import { RiScissorsCutLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineWorkHistory } from "react-icons/md";

import { BeforeAfter } from "react-simple-before-after";

import After_Slider_1 from "../assets/img/after-before/color/after-1.webp";
import After_Slider_2 from "../assets/img/after-before/color/after-2.webp";
import After_Slider_3 from "../assets/img/after-before/color/after-3.webp";
import After_Slider_4 from "../assets/img/after-before/color/after-4.webp";
import After_Slider_5 from "../assets/img/after-before/color/after-5.webp";
import After_Slider_6 from "../assets/img/after-before/color/after-6.webp";

import Before_Slider_1 from "../assets/img/after-before/color/before-1.webp";
import Before_Slider_2 from "../assets/img/after-before/color/before-2.webp";
import Before_Slider_3 from "../assets/img/after-before/color/before-3.webp";
import Before_Slider_4 from "../assets/img/after-before/color/before-4.webp";
import Before_Slider_5 from "../assets/img/after-before/color/before-5.webp";
import Before_Slider_6 from "../assets/img/after-before/color/before-6.webp";

import Service_accodian from "../assets/img/color-matching-service.webp";

import Logo_png from "../assets/img/logo.png";
import Logo_webp from "../assets/img/logo.webp";
import { lazy, Suspense } from "react";
import Img_Icon from "../assets/img/img-icon.webp";
import Img_Icon_1 from "../assets/img/banner-icon-1.webp";
import Img_Icon_2 from "../assets/img/banner-icon-2.webp";
import Img_Icon_3 from "../assets/img/banner-icon-3.webp";
import Img_Icon_4 from "../assets/img/banner-icon-4.webp";

import Img_Banner_1 from "../assets/img/Color_Correction-service-banner.webp";

import Testi_1 from "../assets/img/testi-icon-1.webp";
import Feature_New_1 from "../assets/img/feature-new-3.webp";

import Satisfied from "../assets/img/100-satisfied.webp";
import Marquee from "react-fast-marquee";

import Company_1 from "../assets/img/px-conversions/company-1.webp";
import Company_2 from "../assets/img/px-conversions/company-2.webp";
import Company_3 from "../assets/img/px-conversions/company-3.webp";
import Company_4 from "../assets/img/px-conversions/company-4.webp";
import Company_5 from "../assets/img/px-conversions/company-5.webp";
import Company_6 from "../assets/img/px-conversions/company-6.webp";
import Company_7 from "../assets/img/px-conversions/company-7.webp";
import { BsShieldFillCheck } from "react-icons/bs";

import Gallery_img_1 from "../assets/img/protfolio/color-correction/gallery-1.webp";

import Gallery_img_2 from "../assets/img/protfolio/color-correction/gallery-2.webp";

import Gallery_img_3 from "../assets/img/protfolio/color-correction/gallery-3.webp";

import Gallery_img_4 from "../assets/img/protfolio/color-correction/gallery-4.webp";

import { Helmet } from "react-helmet";
import Gallery_img_5 from "../assets/img/protfolio/color-correction/gallery-5.webp";

import { LazyLoadImage } from "react-lazy-load-image-component";
import pMinDelay from "p-min-delay";

const About_setion2 = lazy(() => pMinDelay(import("./home/About_setion.js"), 5000));

const Color_Matching = () => {
  var settings_1 = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Helmet>
        <title>Photo Color Correction and Recoloring Services | Visuals Clipping</title>
        <meta name="description" content="Services for photo colour recoloring and restoration are offered by Visuals Clipping. We provide the best quality at a competitive price. You can count on our team to make you look your best." />
        <link rel="canonical" href="https://www.visualsclipping.com/photo-color-correction-service" />
      </Helmet>

      <section className="c-banner-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-con">
                <h5>
                  <img src={Img_Icon} alt="visuals clipping " />
                  Color Matching
                </h5>
                <h1>
                  Photo <span> Color Correction </span> Services!
                </h1>
                <ul>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_1} alt="visuals clipping " />
                      </span>
                      100% Satisfaction
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_2} alt="visuals clipping " />
                      </span>
                      No Advanced Payment
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_3} alt="visuals clipping " />
                      </span>
                      Unlimited Revision
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_4} alt="visuals clipping " />
                      </span>
                      10 Image Free Trail
                    </div>
                  </li>
                </ul>

                <div className="c-banner-btn">
                  <Link to="/contact" className="c-btn-1">
                    <span> Get Quote Now</span>
                  </Link>
                  <button data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
                    <span> Get a Free Trial</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-slider">
                <div className="c-banner-slider-list-w">
                  <Slider {...settings_1}>
                    <div className="c-banner-slider-list">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={729}
                        src={Img_Banner_1}
                        width={679}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                    <div className="c-banner-slider-list">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={729}
                        src={Img_Banner_1}
                        width={679}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-about-w c-featurw-pw">
        <div className="container">
          <div className="c-about-hw">
            <h2>
              Benefits to outsource Photo Retouching <br></br> Services to <span>Photo Color Correction</span>{" "}
            </h2>
            <p>You are not restricted to choices when you choose Visuals Clipping. Our clients receive personalized services as per</p>
          </div>
          <div className="cp-fea-list">
            <div className="cp-fea-list-box">
              <div className="cp-fea-list-box-in">
                <span>
                  <LuTimerReset />
                </span>
                Time Saving
              </div>
            </div>
            <div className="cp-fea-list-box">
              <div className="cp-fea-list-box-in">
                <span>
                  <RiScissorsCutLine />
                </span>
                Accuracy
              </div>
              <div className="cp-fea-list-box-in">
                <span>
                  <MdOutlineDesignServices />
                </span>
                100% <br></br>Customized <br></br> Service
              </div>
            </div>

            <div className="cp-fea-list-box c-fea-midel">
              <div className="cp-fea-list-box-in">
                <picture>
                  <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
                  <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo" />
                  <img src={Logo_png} alt="yazzoo homepage banner" />
                </picture>
              </div>
            </div>
            <div className="cp-fea-list-box c-fea-right">
              <div className="cp-fea-list-box-in">
                <span>
                  <MdOutlineWorkHistory />
                </span>
                Professional <br></br> Work
              </div>
              <div className="cp-fea-list-box-in">
                <span>
                  <IoPricetagsOutline />
                </span>
                Cost Effective
              </div>
            </div>
            <div className="cp-fea-list-box c-fea-right">
              <div className="cp-fea-list-box-in">
                <span>
                  <BsShieldFillCheck />
                </span>
                Highest <br></br>standard
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-service-accodian">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="c-service-img">
                <LazyLoadImage
                  alt={"visuals clipping"}
                  height={571}
                  src={Service_accodian}
                  width={1345}
                  wrapperProps={{
                    style: { transitionDelay: "0.1s" },
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="c-about-hw c-faq-group-in c-service-faq">
                <h2>
                  Extensive <span> photo color</span> correction{" "}
                </h2>
                <p>
                  Are you having problems with inaccurate product colors with white balance and overall poor quality in your online photos? Image Color correction solutions from Visuals Clipping can help elevate your eCommerce images to another level via image enhancement solutions and keep you
                  ahead of the competition. If you use professional color correction services, you can sell more items and minimize the return.
                  <br></br>
                  Boost your images' color accuracy with a wide range of color matching and color correction services for e-commerce and print media. Color correction is a process that can be used to adjust the colors in a photo to achieve the desired look. This can be done with a number of
                  different methods, including using editing software, filters, and other digital tools. The goal of color correction is to make the colors in a photo look natural, accurate, and balanced. At our company, we offer extensive photo color correction services that are designed to bring
                  out the desired results and quality in your images. Our team of experts are skilled in a variety of color correction techniques and can tailor our services to meet your specific needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-service-tab-w">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Quick and   <span>Effective</span> Clipping Path Service{" "}
            </h2>
            <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! Here are the top reasons why we should be your go-to choose for all your photo editing and retouching needs:</p>
          </div>
          <div className="c-service-tab">
            <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" data-bs-toggle="pill" data-bs-target="#cp-tab-1" type="button" role="tab" aria-selected="true">
                  Color Corection
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="pill" data-bs-target="#cp-tab-2" type="button" role="tab" aria-selected="false">
                  Color Enhancement
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button class="nav-link " data-bs-toggle="pill" data-bs-target="#cp-tab-3" type="button" role="tab" aria-selected="true">
                  Swatch Matching
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="cp-tab-1" role="tabpanel" tabindex="0">
                <div className="row">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_2}
                      afterImage={After_Slider_2}
                      /* Other Props */
                    />
                  </div>
                  <div className="col-md-6">
                    <BeforeAfter beforeImage={Before_Slider_4} afterImage={After_Slider_4} />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="cp-tab-2" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_3}
                      afterImage={After_Slider_3}
                      /* Other Props */
                    />
                  </div>
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_5}
                      afterImage={After_Slider_5}
                      /* Other Props */
                    />
                  </div>
                </div>
              </div>

              <div class="tab-pane fade " id="cp-tab-3" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_1}
                      afterImage={After_Slider_1}
                      /* Other Props */
                    />
                  </div>
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_6}
                      afterImage={After_Slider_6}
                      /* Other Props */
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cn-feature-main cp-service-feature">
        <div className="container">
          <div className="c-feature-w">
            <div className="c-heading-w">
              <h2>
                What <span>We Offer</span>{" "}
              </h2>
              <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! Here are the top reasons why we should be your go-to choose for all your photo editing and retouching needs: </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>professional color matching service for e-commerce</h3>
                  <p>
                    At <a href="https://visualsstock.co.in/">Visuals Clipping</a>, we understand the importance of high-quality <a href="https://visualsstock.co.in/ecommerce-image-editing-service">product photos for e-commerce businesses</a>. That's why we offer a professional color matching service
                    that can help you boost your sales and conversions. With our color matching service, you can have peace of mind knowing that your <a href="https://visualsstock.co.in/ecommerce-image-editing-service">product photos</a> are accurate and true-to-life. We'll work with you to match
                    the colors in your photos to your brand standards, so that your customers know exactly what they're getting when they purchase from you. If you're looking for a way to improve your <a href="https://visualsstock.co.in/ecommerce-image-editing-service">ecommerce photos</a> and make
                    them more sellable, our color matching service is the perfect solution.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Swatch matching service</h3>
                  <p>
                    A color swatch matching service is a tool that helps you match the colors in your <a href="https://visualsstock.co.in/ecommerce-image-editing-service">e-commerce photos</a> to a set of real-world paint or fabric swatches. This can be helpful if you're trying to create a
                    consistent look for your product photos or if you're trying to match the colors in your photos to a specific color palette. If you are an <a href="https://visualsstock.co.in/ecommerce-image-editing-service">ecommerce business</a>, then you know the importance of high-quality
                    product photos. Studies have shown that product photos are one of the most important factors in driving sales. With hiring our service, you can quickly and easily add color swatches to <a href="https://visualsstock.co.in/ecommerce-image-editing-service">your product photos</a>.
                    This will help you better match your products with the right colors, making it easier for customers to find what they're looking for.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Replace fabric &color of any apparel or furniture</h3>
                  <p>
                    At Visuals Clipping, we offer color matching and correction solutions that can replace the fabric and color of any apparel or furniture in photos. Our services are perfect for businesses that need to update their product photos without incurring the cost of new photography. With
                    our color matching and correction solutions, we can change the fabric and color of any apparel or furniture in photos to match your company's branding. Contact us today to learn more about how we can help you update your product photos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-testimonial-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="c-testi-in">
                <div className="c-heading-w">
                  <h5>
                    <img src={Testi_1} alt="visuals clipping " /> Testimonial
                  </h5>
                  <h2>
                    What Our <span>Client</span> Says?
                  </h2>
                  <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                  <a href="#" className="c-btn-1">
                    <span> Contact</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="c-testi-list">
                <div className="slider-container">
                  <Suspense fallback={<p> Loding </p>}>
                    <About_setion2 />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-price-table-w">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Color Matching <span> Price Chart </span>{" "}
            </h2>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tr>
                <th>Work</th>
                <th>Basic ($ 0.3/image)</th>
                <th>Medium ($ 0.6/image)</th>
                <th>Advance ($ 0.8 /image)</th>
              </tr>
              <tr>
                <td>
                  {" "}
                  <strong>Color correction</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Color Enhancement</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Swatch Matching</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>SKU's color matching</strong>{" "}
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Maximum Image Size</strong>{" "}
                </td>
                <td>1500x1500</td>
                <td>2000X2000</td>
                <td>No Size Limit</td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Image padding</strong>{" "}
                </td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
                <td></td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Image Renaming</strong>{" "}
                </td>
                <td></td>
                <td></td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>

              <tr>
                <td>
                  {" "}
                  <strong>Multiple Format (JPG, PNG, Tiff, PSD, Other)</strong>{" "}
                </td>
                <td></td>
                <td></td>
                <td>
                  <span>
                    <PiSealCheckDuotone />
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>

      <section className="c-portfolio-service">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Our Photo Editing  <span>Portfolio </span>{" "}
            </h2>
            <p>Transforming Ordinary Images into Extraordinary Visuals.</p>
          </div>
          <div className="c-porfolio-in">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="c-porfolio-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={670}
                    src={Gallery_img_1}
                    width={857}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_2}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_3}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_4}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_5}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <Link to="/contact" className="c-btn-1">
                <span>Get a Free Trial</span>{" "}
              </Link>
            </div>
          </div>
          <div className="c-porfolio-con">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="c-porfolio-con">
                  <div className="c-heading-w text-left">
                    <h2>
                      Color correction done <span>quickly and easily.</span>
                    </h2>
                    <p>
                      <a href="https://www.visualsclipping.com/">Visuals Clipping</a> &nbsp;offers the best photo color correction services in the industry. Our advanced AI technology can handle high volumes of images and still achieve the shortest delivery times. <br></br> We use AI and ML to
                      retouch photos, remove blemishes, and even add makeup. We can also change the color of our eyes, add filters, and more. The possibilities are endless. Not only does this save us time, but it also allows us to create stunning photos that we never could have achieved with
                      traditional methods.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="c-porfolio-con">
                  <div className="c-heading-w text-left">
                    <h2>
                      Color Matching & Correction services for <span>eCommerce</span>
                    </h2>
                    <p>
                      Via our solutions, we offer high-end &nbsp; <a href="https://www.visualsclipping.com//jewelry-retouching-service">photo retouching services</a>&nbsp;to help our clients achieve the perfect look for their&nbsp;
                      <a href="https://www.visualsclipping.com/ecommerce-image-editing-service">product images</a>. One of the things we specialize in is creating multiple colors for the same product. This is a great way to make your product stand out from the competition and attract the attention
                      of potential customers. We use a variety of techniques to achieve this, including color correction, color grading, and color balancing. We also offer a wide range of color profiles to choose from, so you can be sure to find the perfect match for your product. If a product has
                      multiple images, each image will be edited to have accurate colors. This is to ensure that the customer does not receive a product that does not look like the image. Also, if a customer were to receive a product that does not look like the image, they might leave a negative
                      review. To maintain our high customer satisfaction rate, each image will be edited to have the correct colors. It is especially important for furniture fabric replacement, and our services never compromise on quality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-about-w c-inner-company">
        <div className="container">
          <div className="c-about-hw">
            <h2>
              Our Trusted <span>Customers</span>{" "}
            </h2>

            <LazyLoadImage
              alt={"visuals clipping"}
              height={82}
              src={Satisfied}
              width={224}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
          </div>
          <Marquee>
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_1}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_2}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_3}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_4}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_5}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_6}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_7}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
          </Marquee>
        </div>
      </section>
    </>
  );
};

export default Color_Matching;
