import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy regarding the collection | Visuals Clipping</title>
        <meta name="description" content="Read our Privacy Policy regarding the collection at the Visuals Clipping website so you do not have any queries related to your current services or future services. Visit Now." />
        <link rel="canonical" href="https://www.visualsclipping.com/privacy-policy" />
      </Helmet>
      <section className="c-inner-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-9 col-md-8">
              <div className="c-inner-banner-con">
                <h3>Privacy Policy</h3>
                <p>Last update: Jan 2020</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="c-inner-banner-img">
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-about-w c-faq-group-main c-privacy-w">
        <div className="container">
          <div className="c-about-hw c-faq-group-in text-left">
            <div className="row">
              <div className="col-md-12">
                <div className="contact-form">
                  <p>
                    Visuals Clipping respects your privacy and this page informs you of our policies regarding the collection, use and disclosure of Personal Data we receive when you visit our website, and use of our services. By using our website or our services, you agree to the collection and use
                    of information in accordance with this policy.
                  </p>
                  <p>
                    The security of your Personal Data is important to us, but remembers that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its
                    absolute security.
                  </p>
                  <br></br>
                  <h2 className="main-sub-heading mb-4">Personal Data</h2>
                  <p>By using our website or our services you may leave certain information behind that may include Personal Data, such as your name and your email address.</p>
                  <p>We will only use your Personal Data needed:</p>
                  <p>To provide the service;</p>
                  <p>To manage your company's account.</p>
                  <p>To enable the processing of payments.</p>
                  <p>For marketing purposes and to inform you about developments and changes to our services / products.</p>
                  <p>
                    We will not share Personal Data with third parties without your prior consent except where Visuals Clipping is required to do so by law or as is necessary to perform our services. If we process Personal Data on your behalf we comply with all obligations under the EU General Data
                    Protection Regulation and ensure that our use complies with our commitments to you.
                  </p>
                  <br></br>
                  <h2 className="main-sub-heading mb-4">Log Data</h2>
                  <p>
                    Like many site operators, we collect and use information that your browser sends whenever you visit our website (“Log Data”) that is not personally identifiable such as your computer's Internet Protocol (“IP”) address, browser type, browser version, the pages of our site that you
                    visit, the time and date of your visit, the time spent on those pages and other statistics. In addition, we may use third party services such as Google Analytics and Hot jar that collect, monitor and analyze this. The information we share will not include Personal Data such as
                    your name and your email address.
                  </p>
                  <br></br>
                  <h2 className="main-sub-heading mb-4">Visual Content Data</h2>
                  <p>
                    Visuals Clipping may store and use Visual Content Inputs (e.g. image and video inputs) processed by the service solely to provide and maintain the service and to improve and develop the quality of our machine-learning/artificial-intelligence technologies and the training of our
                    designers. The use of your content is necessary for continuous improvement of our services, including the development and training of related services. Except for the features belonging to visual content (such as the models included in the visual content), we do not store or use
                    any personally identifiable information that may be contained in your Visual Content Inputs.
                  </p>
                  <br></br>
                  <h2 className="main-sub-heading mb-4">Third Parties</h2>
                  <p>
                    Visuals Clipping may engage its service network for the provision of its services. The service network Visuals Clipping has established consists of a number of carefully selected professional partners with which Visuals Clipping has agreements in place to guarantee the same level
                    of protection you expect from us.
                  </p>
                  <p>
                    Our service network includes our production facilities that are located in India. We never share Personal Data such as your name and your email address with our production facilities. However, our production facilities may have access to the Visual Content Inputs you provided and
                    use it to perform the required services. Besides our production facilities we also use companies like Google, Microsoft, Adobe, Exact and Sales force to provide our systems and services.
                  </p>
                  <p>We guarantee that everything we commit ourselves to pursuant this agreement, also applies to our service network and that we regard their services as part of our responsibility towards you.</p>
                  <br></br>
                  <h2 className="main-sub-heading mb-4">Storage of Personal Data &amp; Visual Content Inputs</h2>
                  <p>We will keep your Personal Data, whether or not your account is active, as long as we need it for the purpose for which we received your data in the first instance or to comply with applicable laws, audit requirements, regulatory requests or court decisions.</p>
                  <p>
                    After your first request to permanently delete your account, we will archive personally identifiable data, such as your name and your email address for two months. After this period has expired, your personally identifiable data such as your name and email address will be
                    permanently deleted. None personally identifiable data, such as the materials created from the Visual Content Inputs you provide, will be kept for development and training purposes after you have uploaded the visual content.
                  </p>
                  <br></br>
                  <h2 className="main-sub-heading mb-4">Your rights concerning your Personal Data</h2>
                  <p>
                    Please be aware that you shall have the right to obtain confirmation as to what Personal Data are being processed and where that is the case and, if applicable, to rectify or to erase your Personal Data or to transmit those data to another controller. Besides this, you shall have
                    the right to object, on grounds relating to your particular situation, at any time to processing of Personal Data that concerns you.
                  </p>
                  <br></br>
                  <h2 className="main-sub-heading mb-4">Security Measures</h2>
                  <p>We operate sufficient technical and organizational measures to protect your Personal Data against unlawful and unauthorized processing and against accidental loss, destruction or damage. In particular we take the following security measures to protect your privacy:</p>
                  <p>We pseudonym and encrypt Personal Data for our production.</p>
                  <p>Client accounts are encrypted.</p>
                  <p>Access to Personal Data is protected.</p>
                  <p>Secured connections for internal and external data transfers.</p>
                  <p>Secur ed communication with the server (HTTPS / SSL).</p>
                  <p>Data is stored on secured servers.</p>
                  <p>Physical access to the servers and company premises where data is stored is limited to authorized persons.</p>
                  <p>We only use up-to-date licensed software and up-to-date (Internet) security software.</p>
                  <p>For cloud solutions we have 2-step or 2-factor authentication in use.</p>
                  <br></br>
                  <h2 className="main-sub-heading mb-4">Changes to this privacy Policy</h2>
                  <p>
                    We may update this Privacy Policy from time to time, and when we do so, we will update the “last modified” date at the top of this Privacy Policy. By regularly checking this page, you always know what information we collect, how we use it and under what circumstances we share it
                    with other parties.
                  </p>
                  <p>If you would like to view or contest the accuracy of the personal data we hold about you, please send us an email to info@visualsclipping.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
