import React from 'react';
import { lazy, Suspense } from "react";


import { IoPricetagsOutline } from "react-icons/io5";
import { RiMailSendLine } from "react-icons/ri";
import Logo_png from '../assets/img/logo.png' 
import Logo_webp from '../assets/img/logo.webp' 
import { TbPhotoUp } from "react-icons/tb";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Img_Icon from '../assets/img/img-icon.webp';
import Testi_1 from "../assets/img/testi-icon-1.webp";
import Img_Icon_1 from '../assets/img/banner-icon-1.webp';
import Img_Icon_2 from '../assets/img/banner-icon-2.webp';
import Img_Icon_3 from '../assets/img/banner-icon-3.webp';
import Img_Icon_4 from '../assets/img/banner-icon-4.webp';
import Image_banner from '../assets/img/e-commerce/eCommerce-photo-editing-service.webp';
import Feature_New_1 from '../assets/img/landing/landing-icon-2.webp';
import pMinDelay from 'p-min-delay';
import Landing_img_1 from '../assets/img/e-commerce/fashion-accessories-photo-editing.webp';
import Landing_img_2 from '../assets/img/e-commerce/sunglass-photo-editing.webp'
import Landing_img_3 from '../assets/img/e-commerce/apparel-photo-editing-service.webp'
import Contact_form from './Contact_form.js';

import { RxLapTimer } from "react-icons/rx";
import Landing_img_4 from '../assets/img/e-commerce/footwearphoto-editing.webp';
import Landing_img_5 from '../assets/img/e-commerce/furniture-photo-editing-service.webp';
import Landing_img_6 from '../assets/img/e-commerce/jewelry-photo-editing-service.webp';
import Landing_img_7 from '../assets/img/e-commerce/ghost-mannequin-photo-editing.webp';
import Landing_img_8 from '../assets/img/e-commerce/ecommerce-product-photo-editing.webp';
import Landing_img_9 from '../assets/img/e-commerce/ecommerce-image-resizing-padding-a.webp';


const About_setion = lazy(() => pMinDelay(import('./home/About_setion.js'),5000));

const Feature_section = lazy(() => import("./home/Feature_section.js"));



const Ecommerce_photo_editing_service = () => {
    
  return (
    
    <>
    <Helmet>
        <title>Professional eCommerce Photo Editing Services | Visuals Clipping</title>
        <meta name="description" content="Boost sales with professional eCommerce photo retouching from Visuals Clipping. Offering 15+ years of expertise. Start with our free 10-image trial."/>
     </Helmet>

  <section className="c-banner-w c-landing-banner">
	    <div className="container">
		<div className="row align-items-center">
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-con">
					<h5>

          <LazyLoadImage 
						alt={"visuals clipping"}
						height={24}
						src={Img_Icon_1} 
						width={24}  
						
						wrapperProps={{
							
						
						}}
						/>
                    

                    eCommerce  Photo Editing
					</h5>
					<h1>
          eCommerce <span>Photo Editing</span> Services

                    </h1>
             
                    <ul>	
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_1} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								
								100% Satisfaction
							</div>
						</li>	
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_2} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								No Advanced Payment
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_3} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								
								Unlimited Revision
							</div>
						</li>
						<li>
							<div className="c-banner-feature">
							<LazyLoadImage 
						alt={"visuals clipping"}
						height={36}
						src={Img_Icon_4} 
						width={36}  
						
						wrapperProps={{
							
						
						}}
						/>
								
								10 Image Free Trail
							</div>
						</li>
					</ul>
                    
					<div className="c-banner-btn">
						<Link  to="/contact" className="c-btn-1">
							<span>	Get Quote Now</span>
						</Link>
						<button  data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
							<span>	Get a Free Trial</span>
						</button>
					</div>
				</div>
			</div>
			<div className="col-lg-6 col-md-12">
				<div className="c-banner-slider">
					<div className="c-banner-slider-list-w">
                         <div className="c-banner-slider-list">


                           <LazyLoadImage 
                            alt={"eCommerce Photo Editing Service"}
                                            height={592}
                                            src={Image_banner} 
                                            width={678}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            /> 

                          
                            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section className='c-about-w c-featurw-pw landing-about-top'>
    <div className='container'>
        <div className='row'>
            <div className="col-lg-5 col-md-12">
              <div className="c-testi-in">
                  <div className='c-landing-form'>
                      <h3> Get Quote Now</h3>   
                      <Contact_form />                   
                  </div>                          
              </div>
            </div>  
            <div className="col-lg-7 col-md-12">
            <div className='c-about-hw'>
             <h2>Why Choose Visuals Clipping for Your <span>eCommerce Photo Retouching </span> Needs?</h2>
             <p>With over 15 years of experience, Visuals Clipping offers high-quality, tailored photo editing services designed to elevate eCommerce visuals. Our no-advance-payment policy, 10-image free trial, and 24-hour delivery guarantee ensure that you experience our commitment to quality and satisfaction firsthand.</p>
           
        </div>
        <div className='cp-fea-list'>


              <div className='cp-fea-list-box'>
              <div className='cp-fea-list-box-in'>
                  <span>
                  <RiMailSendLine />
                  </span>
                  12 Hours<br></br> Delivery

              </div>
              <div className='cp-fea-list-box-in'>
                  <span>
                  <RxLapTimer />
                  </span>
                  Time  <br></br>Saving 
              </div>
          </div>

          <div className='cp-fea-list-box c-fea-midel'>
              <div className='cp-fea-list-box-in'>
                  <picture>
                      <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
                      <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo"/>
                      <img src={Logo_png} alt="yazzoo homepage banner"  />
                  </picture>
              </div>
          </div>
          <div className='cp-fea-list-box c-fea-right'>
              <div className='cp-fea-list-box-in'>
                  <span>
                  <IoPricetagsOutline />
                  </span>
                  Cost 
                  <br></br> Effective
              </div>
              <div className='cp-fea-list-box-in'>
                  <span>
                    <TbPhotoUp />
                  </span>
                  Professional <br></br> Work
              </div>
          </div>


        </div>                              
            </div>                                   
        </div>                                        
        
    </div>
</section>  
    

      <section className='cn-feature-main cp-service-feature'>
    <div className='container'>
    <div className='c-feature-w'>
          <div className='c-heading-w'>
                <h2>Customized  <span>Photo Editing Services</span> for Every Product Type.</h2>
                <p>We understand that each eCommerce sector, from apparel to jewelry, has unique editing needs. Our team provides 100% customized retouching solutions to highlight the true beauty of your products and help increase conversions. Explore our services designed to meet industry-specific standards. </p>
          </div>
            <div className='row'>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>eCommerce Product Photo Editing
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"eCommerce Product Photo Retouching"}
                                            height={287}
                                            src={Landing_img_8} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Our eCommerce product photo editing ensures each product stands out with flawless detail, attracting more customers and improving sales. We enhance colors, adjust brightness, and remove imperfections, making each item look its best. With tailored adjustments for eCommerce, your product images are refined and ready to drive engagement across any platform.</p>
                       
                    </div>
                </div>

                 <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"Drop-Shadow Creation"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Apparel Photo Editing
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Apparel Photo Editing"}
                                            height={287}
                                            src={Landing_img_3} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>For online clothing retailers, high-quality apparel images are essential. Our apparel photo editing service includes color correction, wrinkle removal, and shadow adjustments, all done to ensure each garment appears vibrant and appealing. We focus on making textures look lifelike, bringing out the best in each piece while maintaining color accuracy.</p>
                       
                    </div>
                </div>   
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Footwear Photo Retouching
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Footwear Photo Retouching"}
                                            height={287}
                                            src={Landing_img_4} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Footwear often requires special attention to details like texture, color, and structure. Our footwear photo retouching service enhances these details, making shoes appear as sharp and realistic as they do in person. From color corrections to removing minor flaws, we ensure your footwear photos look clean, professional, and ready for online display.
                            </p>
                       
                    </div>
                </div>   
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Eyewear Photo Retouching
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Eyewear Photo Editing"}
                                            height={287}
                                            src={Landing_img_2} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Our eyewear retouching service caters to unique challenges, including reflections, shadows, and lens clarity. We expertly refine eyewear images to make them look sleek, stylish, and highly appealing. By enhancing glass and frame details, we help make each pair of eyewear look flawless and inviting.
                            </p>
                       
                    </div>
                </div>   
                

                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Furniture Photo Retouching
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Furniture Photo Editing Service"}
                                            height={287}
                                            src={Landing_img_5} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Furniture photo retouching focuses on capturing the textures and colors of each piece accurately. We edit out any distractions and correct lighting to showcase every item’s appeal. This service is ideal for making sofas, tables, and other pieces look inviting and true-to-life, helping buyers visualize each item in their spaces.
                            </p>
                       
                    </div>
                </div> 
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Ghost Mannequin Retouching
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Ghost Mannequin Retouching & Neck Joint"}
                                            height={287}
                                            src={Landing_img_7} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Our ghost mannequin retouching service provides a professional way to present clothing without a model. By removing the mannequin, we create a 3D effect that highlights garment structure and fit. This approach is perfect for showcasing apparel in a clean, stylish format that keeps attention on the product.
                            </p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>High-end Jewelry Retouching
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"eCommerce Jewelry Retouching Service"}
                                            height={287}
                                            src={Landing_img_6} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>Jewelry demands precision and a keen eye for detail. Our high-end jewelry retouching service enhances each piece’s brilliance, ensuring stones and metals appear clear and true to color. We remove dust, polish surfaces, and adjust lighting for an elegant, luxurious final look that stands out.
                            </p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Fashion Accessories Photo Retouching
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"Fashion Accessories Photo Retouching"}
                                            height={287}
                                            src={Landing_img_1} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>From bags to belts, fashion accessories need to look polished and appealing. Our retouching service for accessories includes color correction, scratch removal, and detailing adjustments. Each item receives a unique touch-up to maintain its allure, making your fashion accessories pop in every shot.
                            </p>
                       
                    </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                    <div className='c-feature-box'>
                    <LazyLoadImage 
                            alt={"visuals clipping"}
                                            height={105}
                                            src={Feature_New_1} 
                                            width={90}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />    
                           
                            <h3>Image Resizing and Padding
                            </h3>
                            <div className='c-landing-box-img'>
                            <LazyLoadImage 
                            alt={"eCommerce Image Resizing & Padding"}
                                            height={287}
                                            src={Landing_img_9} 
                                            width={360}  
                                            
                                            wrapperProps={{
                                                
                                               
                                            }}
                                            />              
                            </div>
                            <p>To ensure images fit perfectly on various eCommerce platforms, our image resizing and padding service adjusts dimensions and aligns proportions without sacrificing quality. We prepare your images for consistent online display, helping products look professional and polished across all digital channels.
                            </p>
                       
                    </div>
                </div>

                                     
               
               
            </div>
        </div>
    </div>
</section>   
<section className="c-testimonial-w c-landing-teti-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="c-testi-in">
                  <div className='c-landing-form'>
                      <h3> Get Quote Now</h3>   
                      <Contact_form />                   
                  </div>                          
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="c-testi-list mt-0">
              <div className="c-testi-in mb-5">
                <div className="c-heading-w">
                  <h5>
                    <img src={Testi_1} alt="visuals clipping " /> Testimonial
                  </h5>
                  <h2>
                    What Our <span>Client</span> Says?
                  </h2>
                  <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                  
                </div>
              </div>
                <div className="slider-container">
                  <Suspense fallback={<p> Loding </p>} >
                    <About_setion />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
     <Suspense fallback={<p className="c-feature-lodder"> Loding... </p>}>
        <Feature_section />
      </Suspense>
      <section className="c-faq-w landing-faq">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Frequently <span>Asked</span> Questions
            </h2>
          </div>
          <div className="row">
            
            <div className="col-lg-7 col-md-12 mt-auto mb-auto">
              <div className="c-faq-list">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      How do I know the quality of your eCommerce retouching service before committing?
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We offer a 10-image free trial to showcase our quality and precision before you proceed with a full order.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      What file formats do you support for delivery?
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We support JPEG, PNG, PSD, and TIFF formats based on your preferences.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Do you offer discounts for bulk orders?
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>
                        Yes, we offer special pricing for large projects. Contact us for a custom quote.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                      What is the average turnaround time for photo editing?
                      </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We offer a standard 24-hour delivery, with rush delivery available in 3-6 hours based on project needs.</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading5">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                      Is there a minimum or maximum number of images I can send?
                      </button>
                    </h2>
                    <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>No, we handle projects of all sizes—from single images to large batches—tailoring our service to meet your needs.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading6">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapseThree">
                      How does Visuals Clipping ensure image confidentiality?
                      </button>
                    </h2>
                    <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We use secure file transfer systems, and all images are treated with strict confidentiality to protect your brand and content.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading7">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                      What payment options do you offer?
                      </button>
                    </h2>
                    <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>We operate on a no-advance-payment basis, letting you assess our service quality before payment is required. We accept PayPal, Wise, and wire transfer.</p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading7">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapseThree">
                      Can you handle complex products like jewelry?
                      </button>
                    </h2>
                    <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Yes, we specialize in high-detail retouching, providing precision edits for complex items like jewelry</p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading7">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapseThree">
                      Are there unlimited revisions?
                      </button>
                    </h2>
                    <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <p>Absolutely. We offer unlimited revisions to ensure you’re 100% satisfied with the final images.</p>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="c-faq-img">
                  <div className='c-landing-form'>
                      <h3> Get Quote Now</h3>   
                      <Contact_form />                   
                  </div>                          
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  )
}

export default Ecommerce_photo_editing_service
