import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Updated for React Router v6
import { useHistory } from "react-router-dom";
const ContactForm = () => {
  const history = useHistory(); // Updated for React Router v6
  const fileTypes = ["JPG", "JPEG", "Tiff", "PSD", "PNG", "GIF", "WEBP", "PDF", "PPT", "CR2", "CR3", "NEF", "CRW", "PEF", "DNG", "tif"];
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    message: "",
    link: "",
  });
  const [loading, setLoading] = useState(false); // Loader state
  const [error, setError] = useState(null); // Error state

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!formData.name || !formData.email || !formData.tel || !formData.message) {
      setError("All fields are required.");
      return;
    }

    setLoading(true); // Show loader
    setError(null); // Reset error state

    const data = new FormData();
    data.append("name", formData.name);
    data.append("email", formData.email);
    data.append("tel", formData.tel);
    data.append("message", formData.message);
    data.append("link", formData.link);
    if (file) {
      for (let i = 0; i < file.length; i++) {
        data.append("files[]", file[i]);
      }
    }

    try {
      const response = await axios.post("/send-mail/mail.php", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === "success") {
        window.location.replace("/thank-you");
      } else {
        throw new Error(response.data.message || "An error occurred.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        setError(error.response.data.message); // Show error message from API
      } else {
        setError("An error occurred while submitting the form.");
      }
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <div className="cp-contact-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input name="name" type="text" required placeholder="Name*" className="form-control" value={formData.name} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <input name="email" type="email" required placeholder="Email*" className="form-control" value={formData.email} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <input name="tel" type="tel" required placeholder="Phone Number*" className="form-control" value={formData.tel} onChange={handleInputChange} />
        </div>
        <div className="form-group">
          <textarea name="message" placeholder="Your message..." required className="form-control" value={formData.message} onChange={handleInputChange} />
        </div>

        <div className="form-group">
          <input name="link" type="url"  placeholder="Free Trial Image Link" className="form-control" value={formData.link} onChange={handleInputChange} />
        </div>

        {/* <div className="form-group c-file-upload-w">
          <p>Upload images (MAX 100 MB)</p>
          <FileUploader maxSize={100} multiple={true} handleChange={handleFileChange} name="attachment" types={fileTypes} />
        </div>
        <p>{file ? `Number of files: ${file.length}` : "No files uploaded yet"}</p>
        {error && <div className="alert alert-danger">{error}</div>} Display error message */}
        <button type="submit" className="c-btn-1" disabled={loading}>
          {loading ? (
            <span>Sending...</span> // Show loader text during submission
          ) : (
            <span>Submit</span>
          )}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
