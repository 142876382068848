import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { PiSealCheckDuotone } from "react-icons/pi";
import { MdOutlineDesignServices } from "react-icons/md";
import { LuTimerReset } from "react-icons/lu";
import { RiScissorsCutLine } from "react-icons/ri";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdOutlineWorkHistory } from "react-icons/md";

import { BeforeAfter } from "react-simple-before-after";

import After_Slider_1 from "../assets/img/after-before/car/after-1.webp";
import After_Slider_2 from "../assets/img/after-before/car/after-2.webp";
import After_Slider_3 from "../assets/img/after-before/car/after-3.webp";
import After_Slider_4 from "../assets/img/after-before/car/after-4.webp";
import After_Slider_5 from "../assets/img/after-before/car/after-5.webp";

import Before_Slider_1 from "../assets/img/after-before/car/before-1.webp";
import Before_Slider_2 from "../assets/img/after-before/car/before-2.webp";
import Before_Slider_3 from "../assets/img/after-before/car/before-3.webp";
import Before_Slider_4 from "../assets/img/after-before/car/before-4.webp";
import Before_Slider_5 from "../assets/img/after-before/car/before-5.webp";

import Service_accodian_png from "../assets/img/car-acco-img.png";
import Service_accodian from "../assets/img/car-acco-img.webp";

import Logo_png from "../assets/img/logo.png";
import Logo_webp from "../assets/img/logo.webp";
import { lazy, Suspense } from "react";
import Img_Icon from "../assets/img/img-icon.webp";
import Img_Icon_1 from "../assets/img/banner-icon-1.webp";
import Img_Icon_2 from "../assets/img/banner-icon-2.webp";
import Img_Icon_3 from "../assets/img/banner-icon-3.webp";
import Img_Icon_4 from "../assets/img/banner-icon-4.webp";

import Img_Banner_1 from "../assets/img/vehicle-photo-editing-banner.webp";
import Img_Banner_1_png from "../assets/img/vehicle-photo-editing-banner.png";

import Chat_img from "../assets/img/chat.webp";
import Chat_img_png from "../assets/img/chat.png";
import Testi_1 from "../assets/img/testi-icon-1.webp";
import Feature_New_1 from "../assets/img/feature-new-3.webp";
import Feature_New_2 from "../assets/img/feature-new-2.webp";
import Feature_New_3 from "../assets/img/feature-new-1.webp";

import { Helmet } from "react-helmet";
import Satisfied from "../assets/img/100-satisfied.webp";
import Marquee from "react-fast-marquee";

import Company_1 from "../assets/img/px-conversions/company-1.webp";
import Company_2 from "../assets/img/px-conversions/company-2.webp";
import Company_3 from "../assets/img/px-conversions/company-3.webp";
import Company_4 from "../assets/img/px-conversions/company-4.webp";
import Company_5 from "../assets/img/px-conversions/company-5.webp";
import Company_6 from "../assets/img/px-conversions/company-6.webp";
import Company_7 from "../assets/img/px-conversions/company-7.webp";
import { BsShieldFillCheck } from "react-icons/bs";

import Gallery_img_1 from "../assets/img/protfolio/car/gallery-1.webp";
import Gallery_img_png_1 from "../assets/img/protfolio/car/gallery-1.png";

import Gallery_img_2 from "../assets/img/protfolio/car/gallery-2.webp";
import Gallery_img_png_2 from "../assets/img/protfolio/car/gallery-2.png";

import Gallery_img_3 from "../assets/img/protfolio/car/gallery-3.webp";
import Gallery_img_png_3 from "../assets/img/protfolio/car/gallery-3.png";

import Gallery_img_4 from "../assets/img/protfolio/car/gallery-4.webp";
import Gallery_img_png_4 from "../assets/img/protfolio/car/gallery-4.png";

import Multiple_car_color from "../assets/img/multiple-car-color.webp";

import Gallery_img_5 from "../assets/img/protfolio/car/gallery-5.webp";
import Gallery_img_png_5 from "../assets/img/protfolio/car/gallery-5.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import pMinDelay from "p-min-delay";

const About_setion2 = lazy(() => pMinDelay(import("./home/About_setion.js"), 5000));

const Automotive_Photo_Editing = () => {
  var settings_1 = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Helmet>
        <title>Professional Automotive Photo Editing Service | Vehicle Photo Retouching</title>
        <meta name="description" content="Visuals Clipping provides a comprehensive range of vehicle image editing services, Vehicle image manipulation, retouching &amp; Vehicle Photo Retouching services across the globe."></meta>
        <link rel="canonical" href="https://www.visualsclipping.com/automotive-photo-editing" />
      </Helmet>

      <section className="c-banner-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-con">
                <h5>
                  <img src={Img_Icon} alt="visuals clipping " />
                  Automotive Photo Editing
                </h5>
                <h1>
                  <span> Automotive Photo Editing </span> Services!
                </h1>

                <ul>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_1} alt="visuals clipping " />
                      </span>
                      100% Satisfaction
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_2} alt="visuals clipping " />
                      </span>
                      No Advanced Payment
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_3} alt="visuals clipping " />
                      </span>
                      Unlimited Revision
                    </div>
                  </li>
                  <li>
                    <div className="c-banner-feature">
                      <span>
                        <img src={Img_Icon_4} alt="visuals clipping " />
                      </span>
                      10 Image Free Trail
                    </div>
                  </li>
                </ul>

                <div className="c-banner-btn">
                  <Link to="/contact" className="c-btn-1">
                    <span> Get Quote Now</span>
                  </Link>
                  <button data-bs-toggle="modal" data-bs-target=".c-model-popup-1" className="c-btn-2">
                    <span> Get a Free Trial</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="c-banner-slider">
                <div className="c-banner-slider-list-w">
                  <Slider {...settings_1}>
                    <div className="c-banner-slider-list">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={619}
                        src={Img_Banner_1}
                        width={679}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                    <div className="c-banner-slider-list">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={619}
                        src={Img_Banner_1}
                        width={679}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-about-w c-featurw-pw">
        <div className="container">
          <div className="c-about-hw">
            <h2>
              Benefits to outsource Photo Retouching <br></br> Services to <span>Automotive Photo Editing</span>{" "}
            </h2>
            <p>YWhy Outsource Automotive Retouching to Visuals Clipping?</p>
          </div>
          <div className="cp-fea-list">
            <div className="cp-fea-list-box">
              <div className="cp-fea-list-box-in">
                <span>
                  <LuTimerReset />
                </span>
                Time Saving
              </div>
            </div>
            <div className="cp-fea-list-box">
              <div className="cp-fea-list-box-in">
                <span>
                  <RiScissorsCutLine />
                </span>
                Accuracy
              </div>
              <div className="cp-fea-list-box-in">
                <span>
                  <MdOutlineDesignServices />
                </span>
                100% <br></br>Customized <br></br> Service
              </div>
            </div>

            <div className="cp-fea-list-box c-fea-midel">
              <div className="cp-fea-list-box-in">
                <picture>
                  <source srcset={Logo_webp} type="image/webp" alt="visuals clipping logo" />
                  <source srcset={Logo_png} type="image/jpeg" alt="visuals clipping logo" />
                  <img src={Logo_png} alt="yazzoo homepage banner" />
                </picture>
              </div>
            </div>
            <div className="cp-fea-list-box c-fea-right">
              <div className="cp-fea-list-box-in">
                <span>
                  <MdOutlineWorkHistory />
                </span>
                Professional <br></br> Work
              </div>
              <div className="cp-fea-list-box-in">
                <span>
                  <IoPricetagsOutline />
                </span>
                Cost Effective
              </div>
            </div>
            <div className="cp-fea-list-box c-fea-right">
              <div className="cp-fea-list-box-in">
                <span>
                  <BsShieldFillCheck />
                </span>
                Highest <br></br>standard
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-service-accodian">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="c-service-img">
                <LazyLoadImage
                  alt={"visuals clipping"}
                  height={643}
                  src={Service_accodian}
                  width={857}
                  wrapperProps={{
                    style: { transitionDelay: "0.1s" },
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="c-about-hw c-faq-group-in c-service-faq">
                <h2>
                  Automotive <span>Photo Editing </span> Services{" "}
                </h2>
                <p>
                  <a href="https://www.visualsclipping.com/">Visuals Clipping</a> is a one-stop solution for the car seller who wants to showcase their vehicle <a href="https://visualsstock.co.in/">images in a professional manner</a> to attract more buyers. We have a team of experienced car photo
                  editors who are expert removing car background, improving colors, add custom backgrounds and company logos for automotive dealership.
                </p>

                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div class="accordion accordion-flush" id="accordionFlushExample-2">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-3" aria-expanded="false">
                            Present your car photos in 360°-spin to maximize conversions.
                          </button>
                        </h2>
                        <div id="flush-collapse-3" class="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample-2">
                          <div class="accordion-body">
                            <p class="mb-3">
                              In a world where competition is always harder than you imagine, you are always looking for ways to put yourself ahead. And in car photography, the 360°-car spin is one of the methods that can go a long way toward making your cars' sales grow and expanding your customer
                              base.
                            </p>
                            <p class="mb-3">
                              The 360-spin retouching shows the automobile interior and exterior from all angles in the photographs in high resolution. By adopting this effect, you can attract customers since they can look at the vehicle from all angles. This makes them satisfied in terms of getting
                              a clear visual of the car on display. Moreover, if the effect is good, you can influence their decision in a big way.
                            </p>
                            <p>
                              Instead of opting for a photo studio where you are going to click car with 360°-spin photos with the help of a specialized camera and crew, you can opt to use our advanced AI-backed photo editing and retouching solutions. By using them, you will not only take positive
                              steps towards increasing your sales but also decrease your cost, as this effect is far less expensive than clicking 360°-spin photos in a car studio. Moreover, you will be saving time, and in case you have bulk photos, our team can put the effect on all the photos in a
                              quick time.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-4" aria-expanded="false">
                            360° car spin to maximize conversions.
                          </button>
                        </h2>
                        <div id="flush-collapse-4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample-2">
                          <div class="accordion-body">
                            <p>put yourself ahead. And in car photography, the 360°-car spin is one of the methods that can go a long way toward making your cars' sales grow and expanding your customer base.</p>

                            <p>
                              The 360-spin retouching shows the automobile interior and exterior from all angles in the photographs in high resolution. By adopting this effect, you can attract customers since they can look at the vehicle from all angles. This makes them satisfied in terms of getting
                              a clear visual of the car on display. Moreover, if the effect is good, you can influence their decision in a big way.
                            </p>

                            <p>
                              Instead of opting for a photo studio where you are going to click car with 360°-spin photos with the help of a specialized camera and crew, you can opt to use our advanced AI-backed photo editing and retouching solutions. By using them, you will not only take positive
                              steps towards increasing your sales but also decrease your cost, as this effect is far less expensive than clicking 360°-spin photos in a car studio. Moreover, you will be saving time, and in case you have bulk photos, our team can put the effect on all the photos in a
                              quick time.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-service-tab-w">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Quick and   <span>Effective</span> Clipping Path Service{" "}
            </h2>
            <p>Are you in need of high-quality photo editing and retouching services?Look no further than us! Here are the top reasons why we should be your go-to choose for all your photo editing and retouching needs:</p>
          </div>
          <div className="c-service-tab">
            <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" data-bs-toggle="pill" data-bs-target="#cp-tab-1" type="button" role="tab" aria-selected="true">
                  All Logo
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="pill" data-bs-target="#cp-tab-2" type="button" role="tab" aria-selected="false">
                  Background Remove
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button class="nav-link " data-bs-toggle="pill" data-bs-target="#cp-tab-3" type="button" role="tab" aria-selected="true">
                  Multiple Backgrounds
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="pill" data-bs-target="#cp-tab-4" type="button" role="tab" aria-selected="false">
                  Number Plate
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button class="nav-link " data-bs-toggle="pill" data-bs-target="#cp-tab-5" type="button" role="tab" aria-selected="true">
                  {" "}
                  Shadow{" "}
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="cp-tab-1" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_1}
                      afterImage={After_Slider_1}
                      /* Other Props */
                    />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="cp-tab-2" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_3}
                      afterImage={After_Slider_3}
                      /* Other Props */
                    />
                  </div>
                </div>
              </div>

              <div class="tab-pane fade " id="cp-tab-3" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_4}
                      afterImage={After_Slider_4}
                      /* Other Props */
                    />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade " id="cp-tab-4" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_5}
                      afterImage={After_Slider_5}
                      /* Other Props */
                    />
                  </div>
                </div>
              </div>
              <div class="tab-pane fade " id="cp-tab-5" role="tabpanel" tabindex="0">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <BeforeAfter
                      beforeImage={Before_Slider_2}
                      afterImage={After_Slider_2}
                      /* Other Props */
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cn-feature-main cp-service-feature">
        <div className="container">
          <div className="c-feature-w">
            <div className="c-heading-w">
              <h2>
                What <span>We Offer</span>{" "}
              </h2>
              <p>re you in need of high-quality photo editing and retouching services?Look no further than us! Here are the top reasons why we should be your go-to choose for all your photo editing and retouching needs: </p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Car background removal services.</h3>
                  <p>
                    If you want to present your automotive photos professionally, it's important to eliminate any unnecessary background elements. This can be anything from stray branches and leaves to power lines and trash cans. By removing these elements, you can create a cleaner, more focused
                    image that will really pop. Visuals Clipping is the right choice for eliminating unnecessary backgrounds of your vehicle photos, and we offer a flawless car background removal service. We use the latest editing photoshop software to remove any backgrounds that do not complement
                    the car or other automobiles.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Add shadow/reflection</h3>
                  <p>
                    Adding depth and dimension to your car photos with shadow and reflection effects will help you draw in potential buyers. With years of experience, our team at Visuals Clipping can create the ideal shadow and reflection effect for your car photographs to give them a more realistic
                    appearance.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Custom background solution</h3>
                  <p>
                    Transform your car photos from bland to breathtaking with custom background solutions in photo editing. Hire Visuals Clipping’s capable team to showcase your cars in the perfect setting and increase customer engagement with our custom background service. Our team can create a
                    unique and attractive background to meet your needs.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Remove/replace the number plate.</h3>
                  <p>
                    Companies and individuals need to remove or replace the number plate from the car image for many reasons, such as to ensure security and anonymity for their vehicles or maintaining the aesthetic. Irrespective of the reasons, our team at Visuals Clipping can handle this task for
                    you. Get in touch!
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="c-feature-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={105}
                    src={Feature_New_1}
                    width={90}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />

                  <h3>Add logo in background?</h3>
                  <p>
                    Company logos can be added to photos for a variety of reasons, such as to protect the image from being copied or to ensure that the photographer is credited for the work. Whatever the reason may be, we can help you add watermarks or logos to your photos. We can also help you
                    remove watermarks/logos from photos that you may have sourced from elsewhere. We can also edit the photos to make them look more appealing and attractive. We offer the best automotive photo editing services around. Moreover, if you need to add text or remove stickers, we can do
                    it all. We pride ourselves on our top-of-the-line services and affordable prices. So, if you're in need of a car photo edit, look no further than us! We are committed to providing the highest quality editing services possible to help you make the most of your car photos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-testimonial-w">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="c-testi-in">
                <div className="c-heading-w">
                  <h5>
                    <img src={Testi_1} alt="visuals clipping " /> Testimonial
                  </h5>
                  <h2>
                    What Our <span>Client</span> Says?
                  </h2>
                  <p>With our AI workflows, scaling to meet your photo processing needs is easy!</p>
                  <a href="#" className="c-btn-1">
                    <span> Contact</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="c-testi-list">
                <div className="slider-container">
                  <Suspense fallback={<p> Loding </p>}>
                    <About_setion2 />
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='c-price-table-w'>
    <div className='container'>
        <div className='c-heading-w'>
        <h2>Jewellery Retouching <span> Price Chart </span> </h2>

        </div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <tr>
                    <th>Work</th>
                    <th>Basic ($2/image)</th>
                    <th>Medium ($3.5/image)</th>
                    <th>High- end ($5/image)</th>
                </tr>
                <tr>
                    <td> <strong>Background Removal</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Dust / Reflection removal</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Drop Shadow / Reflection</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Image Resizing</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Maximum Image Size</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Image Enhancement</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Color Correction</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Metal Smoothening</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Metal Recolouring</strong> </td> 
                    <td></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Diamond Replacement</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Gems /  Stone Replacement / Cleaning</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>


                <tr>
                    <td> <strong>Multiple Clipping Path</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Metal Shine Enhancement</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td></td>
                </tr>

                <tr>
                    <td> <strong>Ring Shank Redraw</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Creative Retouching</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Unwanted Elements Removal</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Multiple Format (JPG, PNG, Tiff, PSD, Other)</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>

                <tr>
                    <td> <strong>Focus Stacking</strong> </td> 
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                    <td><span><PiSealCheckDuotone /></span></td>
                </tr>
            </table>
        </div>
    </div>
</section> */}

      <section className="c-portfolio-service">
        <div className="container">
          <div className="c-heading-w">
            <h2>
              Our Photo Editing  <span>Portfolio </span>{" "}
            </h2>
            <p>Transforming Ordinary Images into Extraordinary Visuals.</p>
          </div>
          <div className="c-porfolio-in">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="c-porfolio-box">
                  <LazyLoadImage
                    alt={"visuals clipping"}
                    height={670}
                    src={Gallery_img_1}
                    width={857}
                    wrapperProps={{
                      style: { transitionDelay: "0.1s" },
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_2}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_3}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_4}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="c-porfolio-box">
                      <LazyLoadImage
                        alt={"visuals clipping"}
                        height={328}
                        src={Gallery_img_5}
                        width={417}
                        wrapperProps={{
                          style: { transitionDelay: "0.1s" },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <Link to="/contact" className="c-btn-1">
                <span>Get a Free Trial</span>{" "}
              </Link>
            </div>
          </div>
          <div className="c-porfolio-con">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="c-porfolio-con">
                  <div className="c-heading-w text-left">
                    <h2>
                      want custom backgrounds in your <span>automotive photos?</span>
                    </h2>
                    <p>
                      Looking to add a little extra flair to your automobile photos? We can help you add custom backgrounds that will make your automotive photos look great and better than your competition. Whether you're looking for a background that's scenic, colorful, or just plain fun, we can
                      help you create the perfect image. Moreover, our expert car image editing and car background removal services combined can help create photos that look more professional and attractive. We can even add logos or other branding elements to really make your photos look
                      distinctively better.
                    </p>
                    <p>
                      Adding a shadow effect to automotive images can make a big difference in how they look. Shadows can give depth and dimension to an image, making it seem more realistic. They can also add a sense of movement, making the image more dynamic. If you're looking for a way to make
                      your automotive photos stand out, our shadow effect is the perfect solution. This effect gives your photos a realistic shadow that makes them look simply stunning.
                    </p>
                    <p>
                      By changing the floors of car interior photos, you can ensure that your automobile photos are more appealing and attractive. We can change the look of the entire car floor or change it all together using Photoshop and make it as you wish, so that it fits best for the
                      prospective customers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="c-porfolio-con">
                  <div className="c-heading-w text-left">
                    <h2>
                      Finest car color matching and color <span>correction service</span>
                    </h2>
                    <p>
                      Color correction is the process of adjusting the colors in a digital image to make it look more accurate. This can be important for making sure that your product photos look their best, or for making sure that a video looks natural and not overly saturated. Cast removal is the
                      process of removing unwanted elements from a digital image. This may include things like moles, sunspots, or even obtrusive people or things. We provide the finest car photo editing for color correction and cast removal. We have a team of highly skilled and experienced editors
                      who can fix any imperfections in your photos. Whether it's a small blemish or a major problem, we can help you get the perfect photo.
                    </p>
                    <ul>
                      <li>Car Color Enhancement Service.</li>
                      <li>Show cars in multiple colors by color changing.</li>
                      <li>Remove color cast.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-about-w c-inner-company">
        <div className="container">
          <div className="c-about-hw">
            <h2>
              Our Trusted <span>Customers</span>{" "}
            </h2>

            <LazyLoadImage
              alt={"visuals clipping"}
              height={82}
              src={Satisfied}
              width={224}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
          </div>
          <Marquee>
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_1}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_2}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_3}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_4}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_5}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_6}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
            <LazyLoadImage
              alt={"visuals clipping"}
              height={30}
              src={Company_7}
              width={96}
              wrapperProps={{
                style: { transitionDelay: "0.1s" },
              }}
            />
          </Marquee>
        </div>
      </section>
    </>
  );
};

export default Automotive_Photo_Editing;
