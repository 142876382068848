import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Blog_img from "../assets/img/blog.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Read_More from "../assets/img/read-more-arrow.png";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Pagination from "react-js-pagination";
import queryString from "query-string";

const Blog = () => {
  const [PerPage, setPerpage] = useState(9);
  const [TotalRecord, setTotalRecord] = useState(0);
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    // Load data when the component mounts or location changes
    LoadData(1);
  }, [location.search]); // Trigger when the location.search changes (query params)

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    LoadData(pageNumber); // Load data for the new page
  };

  const LoadData = (pageNumber) => {
    setLoading(true);
    const parsedQuery = queryString.parse(location.search); // Parse query parameters
    const category = parsedQuery.category || ""; // Get category if available

    const params = {
      page: PerPage * (pageNumber - 1), // Offset for pagination
      limit: PerPage, // Number of records per page
      category: category, // Include category in API request
    };

    const queryParams = new URLSearchParams(params).toString(); // Use URLSearchParams for query string

    axios.get(`https://api.visualsclipping.com/blogs?${queryParams}`).then(
      (response) => {
        setData(response.data.result); // Set the data
        setTotalRecord(response.data.totalCount); // Set total count for pagination
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      }
    );
  };

  const formatDateToDayMonth = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getDate();
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const month = months[date.getMonth()];
    return `${day} ${month}`;
  };

  return (
    <>
      <Helmet>
        <title>Our Blogs</title>
        <meta name="description" content="Our aim is to help our readers create beautiful and visually appealing images that will make them stand out in the fashion industry." />
        <link rel="canonical" href="https://www.visualsclipping.com/blog" />
      </Helmet>

      <section className="c-inner-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-8">
              <div className="c-inner-banner-con">
                <h3>Our Blogs</h3>
                <p>Our aim is to help our readers create beautiful and visually appealing images that will make them stand out in the fashion industry.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="c-inner-banner-img">
                <LazyLoadImage
                  alt={"visuals clipping"}
                  height={300}
                  src={Blog_img}
                  width={500}
                  wrapperProps={{
                    style: { transitionDelay: "0.1s" },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-blog-w cp-blog-w">
        <div className="container">
          {loading && (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}

          <div className="row">
            {data.length > 0 &&
              data.map((item, key) => (
                <div className="col-lg-4 col-md-6" key={key}>
                  <div className="c-blog-box">
                    <div className="c-blog-box-img">
                      <dfn>{formatDateToDayMonth(item.createdAt)}</dfn>
                      {item.images && item.images[0] && (
                        <img
                          alt={item.name}
                          height={242}
                          src={"https://cdn.visualsclipping.com/" + item.images[0].replace("/uploads/", "")}
                          width={431}
                          wrapperProps={{
                            style: { transitionDelay: "0.1s" },
                          }}
                        />
                      )}
                    </div>
                    <div className="c-blog-box-con">
                      <div className="c-blog-box-con-in">
                        <h4>{item.name}</h4>
                        <p>{item.description.replace(/<(.|\n)*?>/g, "").substring(0, 110)}...</p>

                        <Link to={"/blog/" + item.slug}>
                          Read More{" "}
                          <LazyLoadImage
                            alt={"Background Removal Services"}
                            height={27}
                            src={Read_More}
                            width={27}
                            effect="blur"
                            wrapperProps={{
                              style: { transitionDelay: "1s" },
                            }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <nav aria-label="Page navigation example">
                {TotalRecord > PerPage && (
                  <Pagination
                    prevPageText="prev"
                    nextPageText="next"
                    firstPageText="first"
                    lastPageText="last"
                    activePage={activePage}
                    itemsCountPerPage={PerPage}
                    totalItemsCount={TotalRecord}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination justify-content-center"
                  />
                )}
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
